import "../index.css";
import { useState } from "react";
import { useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import PlaceContext from "../contexts/PlaceContext";
import korpa from "../photos/korpa.png";
import glovo from "../photos/glovo.png";
import website from "../photos/website.png";
import instagram from "../photos/instagram.png";
import googleReviews from "../photos/google-reviews.png";
import tripAdvisor from "../photos/trip-advisor.png";
import Translation from "../components/Translation";
import LanguageContext from "../contexts/LanguageContext";

export default function OrderStatus() {
  const { place, setPlaceId } = useContext(PlaceContext);

  const [order, setOrder] = useState();
  const params = useParams();

  const { language } = useContext(LanguageContext);
  const { translate } = Translation({ language });

  const orderId = params?.orderId;
  const { placeId } = useParams();

  if (place?.id === parseInt(placeId)) {
    localStorage.setItem("placeId", placeId);
  } else {
    localStorage.removeItem("placeId");
    localStorage.setItem("placeId", placeId);
    localStorage.removeItem("shoppingCart");
  }

  useEffect(() => {
    const fetchOrderData = async () => {
      try {
        const response = await fetch(`/api/orders/${orderId}`);
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        setOrder(data);
      } catch (error) {
        console.error("There was a problem with the fetch operation:", error);
      }
    };

    fetchOrderData(); // Initial fetch

    const intervalId = setInterval(fetchOrderData, 60000); // Repeat every 60 seconds

    return () => clearInterval(intervalId); // Cleanup interval on component unmount
  }, [orderId]);

  useEffect(() => {
    setPlaceId(placeId);
    const today = new Date();
    localStorage.setItem("storedDate", today.toISOString());
    const storedDate = localStorage.getItem("storedDate");
    const storedDateObj = new Date(storedDate);
    const isToday = storedDateObj.toDateString() === today.toDateString();

    if (!isToday) {
      localStorage.removeItem("shoppingCart");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const orderStatus =
    order?.status === "completed"
      ? `${translate("completed")}`
      : `${translate("processing")}`;

  if (!order?.status) {
    return (
      <div
        style={{
          backgroundColor: "black",
          backgroundBlendMode: "darken",
          height: "100vh",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div style={{ color: "white" }} class="spinner-border" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
    );
  } else
    return (
      <>
        <div
          className="orderStatusPage"
          style={{
            backgroundImage: `url(${place.background_image})`,
          }}
        >
          <img
            style={{ position: "absolute", top: "4vh" }}
            src={place?.image}
          ></img>
          <div></div>
          <div style={{ textAlign: "center" }}>
            <h1
              style={{
                color: "white",
                fontSize: "25px",
                font: "lato, sans-serif",
                fontWeight: "900",
                letterSpacing: "4px ",
              }}
            >
              {translate("orderStatus")}
            </h1>
          </div>
          <div style={{ width: "80%", textAlign: "center" }}>
            <h2
              style={{
                color: "white",
                fontSize: "20px",
                font: "lato, sans-serif",
                letterSpacing: "2px",
                marginBottom: "30px",
              }}
            >
              {translate("orderNumber")}: #{order?.id}
            </h2>
            <p
              style={{
                textAlign: "center",
                color: "white",
                borderRadius: "100px",
                background: order?.status === "completed" ? "#0db008" : "red",
                padding: "10px",
                fontSize: "20px",
              }}
            >
              {orderStatus.toUpperCase()}
            </p>
          </div>
          <div style={{ maxWidth: "80%", width: "80vw", textAlign: "center" }}>
            <div
              style={{
                color: "white",
                fontSize: "17px",
                font: "lato, sans-serif",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: "10px",
              }}
            >
              {order?.status === "completed" ? (
                <>
                  <span style={{ marginRight: "5px" }}>
                    {translate("enjoyInMeal")}
                  </span>
                  <span style={{ marginRight: "5px", fontWeight: "300" }}>
                    {translate("visitSocials")}
                  </span>
                  <div className="icons-homepage">
                    {place.instagram_url && (
                      <a
                        href={place.instagram_url}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          src={instagram}
                          alt="instagram"
                          className="icons"
                        />
                      </a>
                    )}
                    {place.client_url && (
                      <a
                        href={place.client_url}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img src={website} alt="website" className="icons" />
                      </a>
                    )}
                    {place.korpa_url && (
                      <a
                        href={place.korpa_url}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img src={korpa} alt="korpa" className="icons"></img>
                      </a>
                    )}
                    {place.glovo_url && (
                      <a
                        href={place.glovo_url}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img src={glovo} alt="glovo" className="icons"></img>
                      </a>
                    )}
                    {place.trip_advisor_url && (
                      <a
                        href={place.trip_advisor_url}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          style={{ width: "40px", height: "40px" }}
                          src={tripAdvisor}
                          alt="trip advisor"
                        ></img>
                      </a>
                    )}
                    {place.google_reviews_url && (
                      <a
                        href={place.google_reviews_url}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          style={{ width: "40px", height: "40px" }}
                          src={googleReviews}
                          alt="google reviews"
                        ></img>
                      </a>
                    )}
                  </div>
                </>
              ) : (
                <>
                  <span style={{ marginRight: "5px" }}>
                    {translate("pageRefresh")}
                  </span>
                </>
              )}
            </div>
          </div>
        </div>
      </>
    );
}
