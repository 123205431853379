import React, { useContext, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import LanguageContext from "../contexts/LanguageContext";
import { Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { addOrder } from "../apis";
import AuthContext from "../contexts/AuthContext";
import { toast } from "react-toastify";
import arrow from "../photos/strelica.svg";
import { Modal } from "react-bootstrap";
import OrderAcceptModal from "./OrderAcceptModal";
import { MdFileDownloadDone } from "react-icons/md";
import { BiSolidMinusCircle, BiSolidPlusCircle } from "react-icons/bi";
import Translation from "./Translation";
import { FaTrashAlt } from "react-icons/fa";
import { v4 as uuidv4 } from "uuid";
import { capitalizeFirstLetter } from "../utils/flags/capitalizeFirstLetter";

const Container = styled.div`
  border-radius: 10px;
  background-color: white;
  height: 80px;
  box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  opacity: ${({ active }) => (active ? 1 : 0.6)};
  > div:first-child {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    background-size: cover;
  }
  > div:last-child {
  }
`;

const ShoppingCart = ({
  items,
  place,
  onAdd,
  onRemove,
  color,
  setShoppingCart,
  shoppingCart,
}) => {
  const totalPrice = useMemo(
    () =>
      items
        ?.map((i) => i.quantity * parseFloat(i.price))
        ?.reduce((a, b) => a + b, 0),
    [items]
  );
  const formattedTotalPrice = totalPrice.toFixed(2);
  const navigate = useNavigate();
  const [tablesWithoutCategories, setTablesWithoutCategories] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);

  const [tableNumber, setTableNumber] = useState();

  const [tables, setTables] = useState();
  const [tableJson, setTableJson] = useState();

  const [napomena, setNapomena] = useState("");
  const [writeNapomena, setWriteNapomena] = useState(false);
  const [acceptModal, setAcceptModal] = useState(false);
  const [order, setOrder] = useState("");
  const auth = useContext(AuthContext);
  const defaultNote = "Nema napomene";

  useEffect(() => {
    let tableData = [];
    place.tables_json?.map((table) => {
      const tables = [];
      const numberOfTables = table?.tables;

      for (let i = 1; i <= numberOfTables; i++) {
        tables.push({
          id: i,
          name: `Table ${i}`,
        });
      }
      tableData.push({
        category: table.table_category,
        tables,
      });
    });
    setTables(tableData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const numberOfTables = place.number_of_tables;
    const tableData = [];

    for (let i = 1; i <= numberOfTables; i++) {
      tableData.push({
        id: i,
        name: `Table ${i}`,
      });
    }
    setTablesWithoutCategories(tableData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const clearShoppingCart = () => {
    const emptyCart = {};
    localStorage.setItem("shoppingCart", JSON.stringify(emptyCart));
    setShoppingCart(emptyCart);
  };

  const { language } = useContext(LanguageContext);
  const { translate } = Translation({ language });
  const [selectedOption, setSelectedOption] = useState("");

  const onWriteNapomena = () => {
    setWriteNapomena(true);
  };

  const onAddNapomena = () => {
    setWriteNapomena(false);
  };

  const onNapomenaChange = (event) => {
    setNapomena(event.target.value);
  };

  const onAddOrder = async () => {
    const orderId = uuidv4(); // Generate UUID and store in variable

    const json = await addOrder(
      orderId,
      {
        place: place.id,
        table: tableNumber,
        note: napomena || defaultNote,
        detail: JSON.stringify(shoppingCart),
        payment_intent: "string",
        amount: JSON.stringify(totalPrice),
        tables_json: JSON.stringify(tableJson),
      },
      auth.token
    );
    setOrder(json);
  };

  const handleOrder = () => {
    if (tableJson || tableNumber) {
      setAcceptModal(true);
      clearShoppingCart();
      onAddOrder();
    } else toast(`${translate("chooseTable")}`, { type: "error" });
  };

  const handleTablePick = (e) => {
    if (tables && tables.length > 0) {
      const [category, tableName] = e.target.value.split("|");
      setTableJson({ category, tableName });
      setTableNumber(0);
    } else {
      setTableNumber(e.target.value);
    }
    setSelectedOption(e.target.value);
  };

  const emptyShoppingCart = () => {
    clearShoppingCart();
    navigate(-1);
  };

  const openSideDishes = (item) => {
    setSelectedItem(item);
  };

  return (
    <>
      <div
        style={{
          backgroundImage: `url(${place.background_image})`,
          backgroundSize: "cover",
          position: "relative",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "30vh",
          backgroundPositionY: "center",
          width: "100vw",
          maxWidth: "480px",
        }}
      ></div>

      <b
        className="parentCategoryName"
        style={{ color: "white", fontSize: "23px" }}
        font={place.font}
      >
        {translate("order")}
      </b>
      <div
        style={{
          position: "relative",
          backgroundColor: `${place.background_color}`,
          paddingTop: "70px",
          minHeight: "60vh",
          overflowY: "auto",
          display: "grid",
          gap: "10px",
          alignContent: "start",
          paddingBottom: "300px",
        }}
        className="onlineMenu"
      >
        <img
          src={arrow}
          className="arrowLeft"
          alt="arrow"
          style={{
            position: "absolute",
            left: "5%",
            top: "25px",
            width: "30px",
            height: "35px",
            cursor: "pointer",
          }}
          onClick={() => navigate(-1)}
        ></img>
        <FaTrashAlt
          className="arrowLeft"
          alt="arrow"
          style={{
            position: "absolute",
            right: "5%",
            top: "30px",
            width: "30px",
            height: "25px",
            cursor: "pointer",
          }}
          color={place.color_1}
          onClick={emptyShoppingCart}
        />
        {items?.map((item) => (
          <div className="d-flex flex-column justify-content-center mx-2">
            <div className="d-flex">
              <Container
                active={item.is_available}
                style={{ width: "70%", marginRight: "20px" }}
              >
                <Col
                  xs={5}
                  style={{
                    backgroundImage: `url(${item.image})`,
                    backgroundPositionY: "center",
                    backgroundPositionX: "center",
                    maxWidth: "35%",
                  }}
                />
                <Col className="d-flex flex-column text-break justify-content-between position-relative">
                  <div>
                    <div
                      style={{ minHeight: "40px" }}
                      className="d-flex justify-content-between flex-row align-items-center mb-2"
                    >
                      <b
                        className="itemName"
                        style={{
                          color,
                        }}
                      >
                        {item["name_" + language]}
                      </b>
                      <div className="d-flex"></div>
                    </div>
                  </div>
                  <div style={{ color, display: "flex" }}>
                    {item.variants && (
                      <span
                        style={{
                          width: "80px",
                          height: "20px",
                          overflow: "scroll",
                          textAlignLast: "start",
                          fontSize: "12px",
                          display: "flex",
                          alignItems: "self-end",
                        }}
                        className="small"
                      >
                        <small>
                          {translate("selected")}: {item.variants}
                        </small>
                        {/* <small>
                          {item.variants.length > 3
                            ? `${translate("flavor")} : ${item.variants}`
                            : `${translate("size")} : ${item.variants}`}
                        </small> */}
                      </span>
                    )}
                    <b
                      className="itemPrice"
                      style={{ color, fontSize: "15px", paddingLeft: "50px" }}
                    >
                      {item.price} {place.currency}
                    </b>
                  </div>
                  <div className="d-flex justify-content-between align-items-end align-self-end">
                    {!item.is_available ? (
                      <small className="text-secondary">Not Available</small>
                    ) : null}
                  </div>
                </Col>
              </Container>
              <div
                className="d-flex flex-column align-items-center"
                style={{ width: "20%", fontSize: "15px", alignSelf: "center" }}
              >
                <span style={{ color: place.color_1 }} className="d-flex">
                  {translate("quantity")}: {item?.quantity}
                </span>
                <div className="d-flex">
                  <BiSolidMinusCircle
                    className="kolicina-ikone"
                    color={place.color_1}
                    width={30}
                    height={30}
                    onClick={() => onRemove(item)}
                    style={{ cursor: "pointer" }}
                  />
                  <BiSolidPlusCircle
                    className="kolicina-ikone"
                    color={place.color_1}
                    width={30}
                    height={30}
                    onClick={() => onAdd(item)}
                    style={{ cursor: "pointer" }}
                  />
                </div>
              </div>
            </div>
            <div className="mt-3 mb-1 align-self-center">
              {item.side_dish && item.side_dish.length > 0 ? (
                <button
                  style={{
                    gap: "40px",
                    border: "1px solid black",
                    paddingTop: "2px",
                    paddingBottom: "2px",
                    fontSize: "14px",
                  }}
                  className="bg-white d-flex justify-content-center align-items-center rounded-pill px-3"
                  onClick={() => openSideDishes(item)}
                >
                  <span className="pr-1 blueTextRemoved font-weight-bold">
                    {capitalizeFirstLetter(translate("sideDishes"))}
                  </span>
                  <span
                    className="rounded-circle bg-danger d-flex text-white align-items-center text-sm justify-content-center"
                    style={{ width: "20px", height: "20px" }}
                  >
                    {item.side_dish.length}
                  </span>
                </button>
              ) : (
                ""
              )}
            </div>
            {selectedItem === item && (
              <div className="testoverlay">
                <div
                  style={{
                    position: "relative",
                    width: "70%",
                    maxHeight: "30vh",
                    margin: "auto",
                    overflowY: "scroll",
                    top: "-5%",
                    maxWidth: "400px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "10px",
                      // Remove position, top, left, transform, and justifyContent styles
                    }}
                  >
                    {item?.side_dish &&
                      item?.side_dish?.map((dish) => {
                        return (
                          <button
                            key={dish[`side_dish_${language}`]}
                            className="activeVariantButton d-flex "
                            value={dish[`side_dish_${language}`]}
                          >
                            {dish[`side_dish_${language}`]}{" "}
                            <span style={{ fontSize: "13px" }}>
                              {dish.price}
                              {place.currency}
                            </span>
                          </button>
                        );
                      })}
                  </div>
                </div>
                <button
                  onClick={() => setSelectedItem(null)}
                  style={{ bottom: "30%", maxWidth: "400px" }}
                  className="potvrdiButton"
                >
                  {translate("back")}
                </button>
              </div>
            )}
          </div>
        ))}
        <div
          style={{
            position: "fixed",
            bottom: "0px",
            width: "100%",
            maxWidth: "480px",
          }}
        >
          <div
            style={{
              backgroundColor: "gray",
              borderTopLeftRadius: "20px",
              borderTopRightRadius: "20px",
              display: "grid",
              alignContent: "center",
            }}
          >
            <div
              className="text-white"
              style={{
                padding: "10px",
                display: "flex",
                justifyContent: "center",
                gap: "7px",
              }}
            >
              {translate("total")}:
              <b>
                {formattedTotalPrice} {place.currency}
              </b>
            </div>
            <div
              style={{
                backgroundColor: "white",
                height: "20vh",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                gap: "10px",
              }}
            >
              <select
                value={selectedOption}
                onChange={handleTablePick}
                style={{
                  borderRadius: "20px",
                  border: "2px solid black",
                  display: "flex",
                  justifyContent: "center",
                  padding: "3px",
                  width: "170px",
                  cursor: "pointer",
                }}
              >
                {/* <img src={arrowDown} width={"20px"} height={"20px"} /> */}
                <option value="" disabled>
                  {translate("chooseTable")}
                </option>
                {tables && tables.length > 0
                  ? tables.map((category) => (
                      <React.Fragment key={category?.id}>
                        <option
                          disabled
                          // onClick={handleTablePick(table.target.value)}
                        >
                          {category.category}
                        </option>
                        {category.tables?.map((table) => (
                          <option value={`${category.category}|${table.name}`}>
                            &nbsp; - {table.name}
                          </option>
                        ))}
                      </React.Fragment>
                    ))
                  : tablesWithoutCategories.map((table) => (
                      <option
                        value={table.id}
                        onChange={(e) => handleTablePick(e.target.value)}
                      >
                        {table.name}
                      </option>
                    ))}
              </select>
              <div
                style={{
                  display: "flex",
                  columnGap: writeNapomena ? "20px" : "40px",
                  justifyContent: "center",
                }}
              >
                {writeNapomena ? (
                  <div
                    className="d-flex align-items-center"
                    style={{ gap: "10px" }}
                  >
                    <div className="inputNapomenaDiv">
                      <input
                        placeholder="Send message"
                        autoFocus
                        style={{ width: "100%", borderRadius: "25px" }}
                        className="form-control"
                        type="text"
                        value={napomena}
                        onChange={onNapomenaChange}
                      ></input>
                    </div>
                    <div
                      style={{
                        width: "30px",
                        height: "30px",
                        cursor: "pointer",
                      }}
                    >
                      <MdFileDownloadDone onClick={onAddNapomena} size="sm" />
                    </div>
                  </div>
                ) : (
                  <>
                    <button
                      style={{
                        backgroundColor: "black",
                        color: "white",
                        borderRadius: "10px",
                        width: "130px",
                        border: "none",
                        padding: "5px",
                      }}
                      onClick={onWriteNapomena}
                    >
                      {translate("note")}
                    </button>
                    <button
                      onClick={handleOrder}
                      style={{
                        backgroundColor: "black",
                        color: "white",
                        borderRadius: "10px",
                        width: "130px",
                        border: "none",
                        cursor: "pointer",
                      }}
                    >
                      {translate("placeOrder")}
                    </button>
                  </>
                )}
              </div>
            </div>

            <Modal
              id="itemModal"
              className="itemModal"
              show={acceptModal}
              onHide={() => navigate(-1)}
              centered
            >
              <div style={{ borderRadius: "40px" }}>
                <Modal.Body className="acceptModalBody">
                  <OrderAcceptModal
                    onDone={() => {
                      navigate(-1);
                    }}
                    order={order}
                  />
                </Modal.Body>
              </div>
            </Modal>
          </div>
        </div>
      </div>
    </>
  );
};

export default ShoppingCart;
