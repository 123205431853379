import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import LanguageContext from "../contexts/LanguageContext";

const RecursiveCategory = ({ category, language, place, width }) => {
  const [checked, setChecked] = useState(false);

  const handleChange = () => {
    setChecked(!checked);
  };
  const renderCategory = (cat) => (
    <div
      key={cat.id}
      style={{
        backgroundImage: `url(${cat.background_image})`,
        backgroundSize: "cover",
        height: "100px",
        marginBottom: "7px",
        backgroundBlendMode: "darken",
        backgroundColor: "rgba(0,0,0, 0.3)",
        borderColor: place.color_2,
        borderRadius: "25px",
        display: " flex",
        border: `2px solid ${place.color_2}`,
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        fontSize: "20px",
      }}
    >
      <b style={{ color: "white" }}>{cat["name_" + language.language]}</b>
    </div>
  );
  const sortCategories = (categories) =>
    categories ? [...categories].sort((a, b) => a.priority - b.priority) : [];

  return (
    <div className="ac-sub">
      <input
        className="ac-input"
        id={`ac-${category.id}`}
        name={`ac-${category.id}`}
        type="checkbox"
        checked={checked}
        onChange={handleChange}
      />
      <label className="ac-label" htmlFor={`ac-${category.id}`}>
        {category.children && category.children.length > 0 ? (
          <div style={{ width: width }}>{renderCategory(category)}</div>
        ) : (
          <Link
            style={{ width: width }}
            key={category.id}
            to={`/menu/${place.id}/${category.id}/items`}
            state={{
              place: place,
            }}
          >
            {renderCategory(category)}
          </Link>
        )}
      </label>
      {category.children && category.children.length > 0 && (
        <article className="ac-text">
          {sortCategories(category.children)
            ?.filter((category) => category.is_available)
            .map((cat) => (
              <RecursiveCategory
                key={cat.id}
                category={cat}
                language={language}
                place={place}
                width={`calc(${width} - 5%)`}
              />
            ))}
        </article>
      )}
    </div>
  );
};

export default function CategoryList({ place, mainCategories }) {
  const language = useContext(LanguageContext);

  const sortCategories = (categories) =>
    categories ? [...categories].sort((a, b) => a.priority - b.priority) : [];

  return (
    <div style={{ width: "100%" }}>
      {sortCategories(mainCategories)
        .filter((category) => category.is_available)
        .map((category) => (
          <div key={category.id} className="ac">
            <RecursiveCategory
              category={category}
              language={language}
              place={place}
              width={`calc(85%)`}
            />
          </div>
        ))}
    </div>
  );
}

