import { Button } from "react-bootstrap";
import styled from "styled-components";

const OperationButton = styled(Button)`
  width: 30px;
  height: 30px;
  margin: 0 10px;
  font-size: 20px;
  line-height: 18px;
  border-radius: 100%;
  background-color: black;
  color: white;
  font-size: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default OperationButton;
