import { Navbar, Nav, Container, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import React, { useContext, useState } from "react";
import { DatePicker } from "antd";
import AuthContext from "../contexts/AuthContext";
import moment from "moment/moment";
import PlaceContext from "../contexts/PlaceContext";

const { RangePicker } = DatePicker;

const MainLayout = ({
  children,
  selectedDate,
  setSelectedDate,
  totalItemsPrice,
}) => {
  const navigate = useNavigate();
  const auth = useContext(AuthContext);
  const [showTotal, setShowTotal] = useState(false);

  const onSignIn = () => {
    navigate("/login");
  };

  const onRegister = () => {
    navigate("/register");
  };

  const onSignOut = () => {
    auth.signOut();
    navigate("/login");
  };

  const goToPlaces = () => {
    navigate("/places");
  };

  const isSingle = window.location.pathname?.match(/^\/places\/\d+\/orders$/);
  const { place } = useContext(PlaceContext);

  return (
    <>
      {isSingle ? (
        <Navbar
          bg="light"
          variant="light"
          className="justify-content-between py-3 mb-4"
        >
          <Nav className="d-flex justify-content-center align-items-center">
            <Navbar.Brand href="/">QR Menu</Navbar.Brand>

            <Nav>
              <Nav.Link onClick={goToPlaces}>Places</Nav.Link>
            </Nav>
          </Nav>

          <Nav style={{ gap: "50px" }} className="d-flex align-items-center">
            <div style={{ textAlign: "center" }}>
              {!showTotal ? (
                <Button
                  onClick={() => setShowTotal(!showTotal)}
                  variant="standard"
                >
                  PRESJEK STANJA
                </Button>
              ) : (
                <div style={{ gap: "10px" }} className="d-flex">
                  <p
                    style={{
                      fontSize: "12px",
                      marginTop: "5px",
                      marginBottom: 0,
                    }}
                  >
                    Presjek stanja{" "}
                    {moment(selectedDate[0]).format("DD-MM-YYYY")} -{" "}
                    {moment(selectedDate[1]).format("DD-MM-YYYY")} je
                    <span style={{ fontWeight: "bold", fontSize: "15px" }}>
                      &nbsp; {totalItemsPrice} {place.currency}
                    </span>
                  </p>
                  <button
                    style={{ width: "25px", height: "25px", fontSize: "12px" }}
                    className="btn btn-danger d-flex justify-content-center align-items-center"
                    onClick={() => setShowTotal(!showTotal)}
                  >
                    X
                  </button>
                </div>
              )}
            </div>

            <RangePicker
              onChange={(values) => {
                if (values && values.length === 2) {
                  // Assuming values is an array of moment objects
                  setSelectedDate([values[0].toDate(), values[1].toDate()]);
                } else {
                  setSelectedDate([
                    moment().startOf("day").toDate(),
                    moment().endOf("day").toDate(),
                  ]);
                }
              }}
            />
          </Nav>

          <Nav>
            {auth.token ? (
              <Nav.Link onClick={onSignOut}>Logout</Nav.Link>
            ) : (
              [
                <Nav.Link key={1} onClick={onSignIn}>
                  Login
                </Nav.Link>,
                <Nav.Link key={2} onClick={onRegister}>
                  Register
                </Nav.Link>,
              ]
            )}
          </Nav>
        </Navbar>
      ) : (
        <Navbar bg="light" variant="light" className="mb-4">
          <Navbar.Brand href="/">QR Menu</Navbar.Brand>

          <Nav>
            <Nav.Link onClick={goToPlaces}>Places</Nav.Link>
          </Nav>

          <Nav className="flex-grow-1 justify-content-end">
            {auth.token ? (
              <Nav.Link onClick={onSignOut}>Logout</Nav.Link>
            ) : (
              [
                <Nav.Link key={1} onClick={onSignIn}>
                  Login
                </Nav.Link>,
                <Nav.Link key={2} onClick={onRegister}>
                  Register
                </Nav.Link>,
              ]
            )}
          </Nav>
        </Navbar>
      )}
      <Container>{children}</Container>
    </>
  );
};

export default MainLayout;

