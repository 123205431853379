import React, { useContext, useState } from "react";
import { Form, Tab, Tabs, Button } from "react-bootstrap";
import {
  DE,
  GB,
  ES,
  FR,
  IT,
  TR,
  BA,
  RS,
  HR,
  ME,
  AL,
  SI,
  NL,
  AT,
  SA,
  US,
} from "country-flag-icons/react/3x2";
import { addTag, updateTag } from "../apis";
import { toast } from "react-toastify";
import AuthContext from "../contexts/AuthContext";
import ImageDropzone from "../containers/ImageDropzone";
import { ChromePicker } from "react-color";

const AddTags = ({
  tag,
  editing,
  setOpenModal,
  setSelectedTag,
  fetchAllTags,
  tagAdded,
  setTagAdded,
}) => {
  const [tagDetails, setTagDetails] = useState(
    tag ?? {
      name_bs: "",
      name_en: "",
      name_de: "",
      name_es: "",
      name_fr: "",
      name_it: "",
      name_tr: "",
      name_sa: "",
      name_rs: "",
      name_sr: "",
      name_hr: "",
      name_me: "",
      name_al: "",
      name_sl: "",
      name_nl: "",
      name_at: "",
      name_en_us: "",
      icon: "",
      color: "#ffffff", // Default color
    }
  );

  const [icon, setIcon] = useState(tag?.icon ?? "");

  const auth = useContext(AuthContext);

  const handleInputChange = (field, value, lang = null) => {
    if (lang) {
      // Update language-specific name
      setTagDetails((prev) => ({
        ...prev,
        ...prev,
        [`name_${lang}`]: value,
      }));
    } else {
      // Update icon or color
      setTagDetails((prev) => ({
        ...prev,
        [field]: value,
      }));
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const data = {
        ...tagDetails,
        icon: icon,
        color: tagDetails.color,
      };
      if (editing) {
        const response = await updateTag(tag.id, data, auth.token);
        if (response) {
          toast.success(`Tag updated successfully.`);
          setTagDetails({
            name_bs: "",
            name_en: "",
            name_de: "",
            name_es: "",
            name_fr: "",
            name_it: "",
            name_tr: "",
            name_sa: "",
            name_rs: "",
            name_sr: "",
            name_hr: "",
            name_me: "",
            name_al: "",
            name_sl: "",
            name_nl: "",
            name_at: "",
            name_en_us: "",
            icon: "",
            color: "#ffffff",
          });
          setOpenModal(false);
          setSelectedTag(null);
          fetchAllTags();
        }
      } else {
        const response = await addTag(data, auth.token);

        if (response) {
          toast.success(`Tag added successfully.`);
          setIcon("");
          setTagAdded(!tagAdded);
          setTagDetails({
            name_bs: "",
            name_en: "",
            name_de: "",
            name_es: "",
            name_fr: "",
            name_it: "",
            name_tr: "",
            name_sa: "",
            name_rs: "",
            name_sr: "",
            name_hr: "",
            name_me: "",
            name_al: "",
            name_sl: "",
            name_nl: "",
            name_at: "",
            name_en_us: "",
            icon: "",
            color: "#ffffff",
          });
        }
      }
    } catch (error) {
      console.error("Error adding tag:", error);
      toast.error("Failed to add tag. Please try again later.");
    }
  };

  // const iconOptions = [
  //   { value: "fa-heart", label: "Heart" },
  //   { value: "fa-star", label: "Star" },
  //   { value: "fa-circle", label: "Circle" },
  // ];

  const renderTabContent = (lang) => (
    <>
      <Form.Group>
        <Form.Label>Tag Name</Form.Label>
        <Form.Control
          type="text"
          name={`name_${lang}`}
          value={tagDetails[`name_${lang}`]}
          onChange={(e) => handleInputChange("names", e.target.value, lang)}
          placeholder="Enter tag name..."
        />
      </Form.Group>

      <Form.Group style={{ gap: "20px" }} className="">
        <Form.Group>
          <Form.Label>Color</Form.Label>
          <ChromePicker
            color={tagDetails.color}
            onChange={(value) => handleInputChange("color", value.hex)}
            disableAlpha
            width="100%"
            required
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Image</Form.Label>
          <ImageDropzone value={icon} onChange={setIcon} />
        </Form.Group>
      </Form.Group>
    </>
  );

  const languages =
    "Bosnian, English, German, Spain, French, Italian, Turkish, Arabic, Serbian Latinic, Serbian Cyrillic, Croatian, Montenegro, Albanian, Slovenian, Dutch, Austrian, American";

  const translate = async () => {
    // setTranslating(true);
    try {
      const message = `give me translation of ${
        tagDetails[`name_bs`]
      } on following languages ${languages} as follows:
      "
      name_bs: translation
      name_en: translation
      name_de: translation
      name_es: translation
      name_fr: translation
      name_it: translation
      name_tr: translation
      name_sa: translation
      name_rs: translation
      name_sr: translation
      name_hr: translation
      name_me: translation
      name_al: translation
      name_sl: translation
      name_nl: translation
      name_at: translation
      name_en_us: translation
      "
      `;
      const res = await fetch("https://api.openai.com/v1/chat/completions", {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          Authorization:
            "Bearer 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",
        },
        body: JSON.stringify({
          messages: [{ role: "system", content: message }],
          model: "gpt-3.5-turbo",
        }),
      });

      const json = await res.json();
      const translationList = {};
      json.choices[0].message.content.split("\n\n").forEach((translation) => {
        translation.split("\n").forEach((translation) => {
          const [langCode, value] = translation.split(":");
          translation.trim(" ");
          translationList[langCode.trim()] = value?.trim();
        });
      });
      setTagDetails((prevDetails) => ({
        ...prevDetails,

        name_bs: tagDetails[`name_bs`],
        name_en: translationList.name_en,
        name_de: translationList.name_de,
        name_es: translationList.name_es,
        name_fr: translationList.name_fr,
        name_it: translationList.name_it,
        name_tr: translationList.name_tr,
        name_sa: translationList.name_sa,
        name_rs: translationList.name_rs,
        name_sr: translationList.name_sr,
        name_hr: translationList.name_hr,
        name_me: translationList.name_me,
        name_al: translationList.name_al,
        name_sl: translationList.name_sl,
        name_nl: translationList.name_nl,
        name_at: translationList.name_at,
        name_en_us: translationList.name_en_us,
      }));
    } finally {
      toast("Tekst je preveden", { type: "success" });
    }
  };

  return (
    <>
      <Form.Label className="font-weight-bold">
        <h5>{editing ? "Edit tag" : "Add tag"}</h5>
      </Form.Label>

      <form onSubmit={handleSubmit}>
        <Tabs
          id="controlled-tab-example"
          className="mb-3"
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(auto-fill, minmax(30px, 1fr))",
            gap: "5px",
            gridAutoFlow: "row",
          }}
        >
          <Tab
            className="langTab"
            eventKey="lang-bs"
            title={
              <BA
                style={{ width: "20px", paddingBottom: "5px" }}
                title="Bosnia and Herzegovina"
              />
            }
          >
            {renderTabContent("bs")}
          </Tab>
          <Tab
            className="langTab"
            eventKey="lang-en"
            title={
              <GB
                style={{ width: "20px", paddingBottom: "5px" }}
                title="Great Britain"
              />
            }
          >
            {renderTabContent("en")}
          </Tab>
          <Tab
            className="langTab"
            eventKey="lang-de"
            title={
              <DE
                style={{ width: "20px", paddingBottom: "5px" }}
                title="Deutchland"
              />
            }
          >
            {renderTabContent("de")}
          </Tab>
          <Tab
            className="langTab"
            eventKey="lang-es"
            title={
              <ES
                style={{ width: "20px", paddingBottom: "5px" }}
                title="Espana"
              />
            }
          >
            {renderTabContent("es")}
          </Tab>
          <Tab
            className="langTab"
            eventKey="lang-fr"
            title={
              <FR
                style={{ width: "20px", paddingBottom: "5px" }}
                title="France"
              />
            }
          >
            {renderTabContent("fr")}
          </Tab>
          <Tab
            className="langTab"
            eventKey="lang-it"
            title={
              <IT
                style={{ width: "20px", paddingBottom: "5px" }}
                title="Italia"
              />
            }
          >
            {renderTabContent("it")}
          </Tab>
          <Tab
            className="langTab"
            eventKey="lang-tr"
            title={
              <TR
                style={{ width: "20px", paddingBottom: "5px" }}
                title="Turkey"
              />
            }
          >
            {renderTabContent("tr")}
          </Tab>
          <Tab
            className="langTab"
            eventKey="lang-sa"
            title={
              <SA
                style={{ width: "20px", paddingBottom: "5px" }}
                title="Arabic"
              />
            }
          >
            {renderTabContent("sa")}
          </Tab>
          <Tab
            className="langTab"
            eventKey="lang-rs"
            title={
              <RS
                style={{ width: "20px", paddingBottom: "5px" }}
                title="Serbian Latinic"
              />
            }
          >
            {renderTabContent("rs")}
          </Tab>
          <Tab
            className="langTab"
            eventKey="lang-sr"
            title={
              <RS
                style={{ width: "20px", paddingBottom: "5px" }}
                title="Serbian Cyrillic"
              />
            }
          >
            {renderTabContent("sr")}
          </Tab>
          <Tab
            className="langTab"
            eventKey="lang-hr"
            title={
              <HR
                style={{ width: "20px", paddingBottom: "5px" }}
                title="Croatian"
              />
            }
          >
            {renderTabContent("hr")}
          </Tab>
          <Tab
            className="langTab"
            eventKey="lang-me"
            title={
              <ME
                style={{ width: "20px", paddingBottom: "5px" }}
                title="Montenegro"
              />
            }
          >
            {renderTabContent("me")}
          </Tab>

          <Tab
            className="langTab"
            eventKey="lang-al"
            title={
              <AL
                style={{ width: "20px", paddingBottom: "5px" }}
                title="Albanian"
              />
            }
          >
            {renderTabContent("al")}
          </Tab>
          <Tab
            className="langTab"
            eventKey="lang-sl"
            title={
              <SI
                style={{ width: "20px", paddingBottom: "5px" }}
                title="Slovenian"
              />
            }
          >
            {renderTabContent("sl")}
          </Tab>
          <Tab
            className="langTab"
            eventKey="lang-nl"
            title={
              <NL
                style={{ width: "20px", paddingBottom: "5px" }}
                title="Dutch"
              />
            }
          >
            {renderTabContent("nl")}
          </Tab>
          <Tab
            className="langTab"
            eventKey="lang-at"
            title={
              <AT
                style={{ width: "20px", paddingBottom: "5px" }}
                title="Austria"
              />
            }
          >
            {renderTabContent("at")}
          </Tab>
          <Tab
            className="langTab"
            eventKey="lang-en_us"
            title={
              <US
                style={{ width: "20px", paddingBottom: "5px" }}
                title="English"
              />
            }
          >
            {renderTabContent("en_us")}
          </Tab>
        </Tabs>

        <Button
          style={{ width: "100%" }}
          variant="standard"
          className="my-4"
          onClick={translate}
        >
          Translate
        </Button>
        <Button
          style={{ width: "100%" }}
          className="my-4"
          variant="standard"
          type="submit"
        >
          {editing ? "Update Tag" : "Add Tag"}
        </Button>
      </form>
    </>
  );
};

export default AddTags;
