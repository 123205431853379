import { IoMdArrowBack } from "react-icons/io";
import { Row, Col, Button } from "react-bootstrap";
import { useParams, useNavigate } from "react-router-dom";
import React, { useState, useEffect, useContext, useMemo, useRef } from "react";

import { completeOrder, fetchOrdersByDate } from "../apis";
import AuthContext from "../contexts/AuthContext";
import MainLayout from "../layouts/MainLayout";
import Order from "../components/Order";
import moment from "moment";
import Zvono from "../photos/zvono.mp3";

const Orders = () => {
  const [inProgressOrders, setInProgressOrders] = useState([]);
  const [completedOrders, setCompletedOrders] = useState([]);
  const [orders, setOrders] = useState([]);

  const prevOrdersRef = useRef([]);

  const params = useParams();
  const navigate = useNavigate();
  const auth = useContext(AuthContext);
  const [showCompleted, setShowCompleted] = useState(false);
  const [selectedDate, setSelectedDate] = useState([
    moment().startOf("day").toDate(),
    moment().endOf("day").toDate(),
  ]);

  const [totalItemsPrice, setTotalItemsPrice] = useState(0);
  const [orderStatus, setOrderStatus] = useState(false);

  const onBack = () => navigate(`/places/${params.id}`);

  const startDate = moment(selectedDate[0]).format("YYYY-MM-DD");
  const endDate = moment(selectedDate[1]).format("YYYY-MM-DD");

  const ordersByDate = async () => {
    const json = await fetchOrdersByDate(
      params.id,
      auth.token,
      startDate,
      endDate
    );
    if (json) {
      setCompletedOrders(
        json
          .filter((order) => order.status === "completed")
          .sort((a, b) => b.id - a.id)
      );
      setInProgressOrders(
        json
          .filter((order) => order.status === "processing")
          .sort((a, b) => b.id - a.id)
      );

      let allOrders = json;

      if (!arraysEqual(allOrders, prevOrdersRef.current)) {
        setOrders(allOrders);
        prevOrdersRef.current = allOrders;

        if (!showCompleted) {
          setOrderStatus(true);
          setTimeout(() => {
            setOrderStatus(false);
          }, 9000);
        }
      }
    }
  };
  // Utility function to compare arrays
  const onCompleteOrder = async (orderId) => {
    const json = await completeOrder(
      orderId,
      { status: "completed" },
      auth.token
    );
    if (json) {
      ordersByDate();
    }
  };

  const today = moment(new Date()).format("YYYY-MM-DD");
  const isToday = startDate === today && endDate === today;

  useEffect(() => {
    ordersByDate();
    if (isToday) {
      const interval = setInterval(() => {
        ordersByDate();
      }, 10000);
      return () => clearInterval(interval);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDate, showCompleted]);

  const handleShowCompleted = () => {
    setShowCompleted(true);
  };

  const handleShowInProgress = () => {
    setShowCompleted(false);
  };

  // useEffect(() => {
  //   if (!showCompleted) {
  //     setOrderStatus("ding ding");
  //     setInterval(() => {
  //       setOrderStatus("test");
  //     }, 2000);
  //   }
  // }, [orders]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const totalPrice = useMemo(
    showCompleted
      ? () =>
          completedOrders
            .map((order) => parseFloat(order.amount) || 0)
            .reduce((a, b) => a + b, 0)
      : () =>
          inProgressOrders
            .map((order) => parseFloat(order.amount) || 0)
            .reduce((a, b) => a + b, 0),
    [inProgressOrders, completedOrders]
  );

  const arraysEqual = (arr1, arr2) => {
    return arr1.length === arr2.length;
  };

  useEffect(() => {
    setTotalItemsPrice(totalPrice);
  }, [totalPrice]);

  return (
    <MainLayout
      totalItemsPrice={totalItemsPrice}
      selectedDate={selectedDate}
      setSelectedDate={setSelectedDate}
    >
      <div className="d-grid justify-content-between align-items-center mb-4">
        <div
          style={{ placeContent: "center", marginBottom: "20px" }}
          className="d-flex align-items-center"
        >
          <Button variant="link" onClick={onBack}>
            <IoMdArrowBack size={25} color="black" />
          </Button>
          <h3 className="mb-0 ml-2 mr-2">My Orders</h3>
        </div>
        <div style={{ display: "flex", gap: "10px", placeContent: "center" }}>
          <Button onClick={handleShowCompleted} variant="standard">
            Show Completed
          </Button>
          <Button onClick={handleShowInProgress} variant="standard">
            Show In Progress
          </Button>
        </div>
      </div>

      <Row className="justify-content-center">
        {showCompleted
          ? completedOrders.map((order) => (
              <Col key={order.id} lg={4} md={6}>
                <Order order={order} />
              </Col>
            ))
          : inProgressOrders.map((order) => (
              <Col key={order.id} lg={4} md={6}>
                <Order
                  order={order}
                  onComplete={() => onCompleteOrder(order?.uuid)}
                />
              </Col>
            ))}
      </Row>
      {orderStatus && isToday && (
        <div className="d-none">
          <audio autoPlay controls>
            <source src={Zvono} type="audio/mp3" />
          </audio>
        </div>
      )}
    </MainLayout>
  );
};

export default Orders;
