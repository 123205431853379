import { Form, Button } from "react-bootstrap";
import React, { useState, useContext } from "react";

import { addPlace } from "../apis";
import AuthContext from "../contexts/AuthContext";

import ImageDropzone from "./ImageDropzone";

const PlaceForm = ({ onDone }) => {
  const [name_en, setName] = useState("");
  const [image, setImage] = useState("");
  const [background_color, setBgColor] = useState("#FFFFFF");
  const [color_1, setTextColor] = useState("#000000");
  const [color_2, setBorderColor] = useState("#000000");

  const auth = useContext(AuthContext);

  const onClick = async () => {
    const json = await addPlace(
      { name_en, image, background_color, color_1, color_2 },
      auth.token
    );
    if (json) {
      setName("");
      setImage("");
      setBgColor("#FFFFFF");
      setTextColor("#000000");
      setBorderColor("#FFFFFF");
      onDone();
    }
  };

  return (
    <div>
      <h4 className="text-center">Place</h4>
      <Form.Group>
        <Form.Label>Name</Form.Label>
        <Form.Control
          type="text"
          placeholder="Enter Name"
          value={name_en}
          onChange={(e) => setName(e.target.value)}
        />
      </Form.Group>

      <Form.Group>
        <Form.Label>Image</Form.Label>
        <ImageDropzone value={image} onChange={setImage} />
      </Form.Group>
      <Button variant="standard" block onClick={onClick}>
        Add
      </Button>
    </div>
  );
};

export default PlaceForm;
