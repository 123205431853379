import { Container, Row, Col } from "react-bootstrap";
import React, { useState, useMemo, useContext } from "react";
import MenuList from "../components/MenuList";
import ShoppingCart from "../components/ShoppingCart";
import PlaceContext from "../contexts/PlaceContext";

const Menu = () => {
  const loadShoppingCartFromLocalStorage = () => {
    const savedCart = localStorage.getItem("shoppingCart");
    return savedCart ? JSON.parse(savedCart) : [];
  };

  const [shoppingCart, setShoppingCart] = useState(
    loadShoppingCartFromLocalStorage
  );

  const [showShoppingCart, setShowShoppingCart] = useState(false);

  const { place } = useContext(PlaceContext);

  const onAddItemtoShoppingCart = (item) => {
    setShoppingCart((prevShoppingCart) => {
      if (Array.isArray(prevShoppingCart)) {
        const existingItemIndex = prevShoppingCart.findIndex(
          (cartItem) =>
            cartItem.id === item.id &&
            cartItem.price === item.price &&
            cartItem.variants === item.variants &&
            JSON.stringify(cartItem.side_dish) ===
              JSON.stringify(item.side_dish)
        );

        if (existingItemIndex !== -1) {
          const updatedShoppingCart = [...prevShoppingCart];
          updatedShoppingCart[existingItemIndex] = {
            ...updatedShoppingCart[existingItemIndex],
            quantity: updatedShoppingCart[existingItemIndex].quantity + 1,
          };
          localStorage.setItem(
            "shoppingCart",
            JSON.stringify(updatedShoppingCart)
          );
          return updatedShoppingCart;
        } else {
          const updatedShoppingCart = [
            ...prevShoppingCart,
            { ...item, quantity: 1 },
          ];
          localStorage.setItem(
            "shoppingCart",
            JSON.stringify(updatedShoppingCart)
          );
          return updatedShoppingCart;
        }
      } else {
        // If prevShoppingCart is not an array, initialize it with the item
        const updatedShoppingCart = [{ ...item, quantity: 1 }];
        localStorage.setItem(
          "shoppingCart",
          JSON.stringify(updatedShoppingCart)
        );
        return updatedShoppingCart;
      }
    });
  };

  const onRemoveItemToShoppingCart = (item) => {
    if (totalQuantity === 1) {
      setShowShoppingCart(false);
    }

    setShoppingCart((prevShoppingCart) => {
      const existingItemIndex = prevShoppingCart.findIndex(
        (cartItem) => cartItem.id === item.id
      );

      if (existingItemIndex !== -1) {
        const updatedShoppingCart = [...prevShoppingCart];
        updatedShoppingCart[existingItemIndex] = {
          ...updatedShoppingCart[existingItemIndex],
          quantity: updatedShoppingCart[existingItemIndex].quantity - 1,
        };

        if (updatedShoppingCart[existingItemIndex].quantity === 0) {
          updatedShoppingCart.splice(existingItemIndex, 1);
        }

        localStorage.setItem(
          "shoppingCart",
          JSON.stringify(updatedShoppingCart)
        );
        return updatedShoppingCart;
      }

      return prevShoppingCart;
    });
  };

  const onPaymentDone = () => {
    setShoppingCart({});
    setShowShoppingCart(false);
  };

  const totalQuantity = useMemo(
    () =>
      Object.keys(shoppingCart)
        .map((i) => shoppingCart[i].quantity)
        .reduce((a, b) => a + b, 0),
    [shoppingCart]
  );
  return (
    <Container className="mt-5 mb-5">
      <Row className="justify-content-center">
        <Col lg={8} style={{ display: "grid", justifyItems: "center" }}>
          {showShoppingCart ? (
            <ShoppingCart
              items={Object.keys(shoppingCart)
                .map((key) => shoppingCart[key])
                .filter((item) => item.quantity > 0)}
              onAdd={onAddItemtoShoppingCart}
              onRemove={onRemoveItemToShoppingCart}
              onPaymentDone={onPaymentDone}
              setShoppingCart={setShoppingCart}
              shoppingCart={shoppingCart}
              color={place.color}
              place={place}
              font={place.font}
            />
          ) : (
            <MenuList
              place={place}
              shoppingCart={shoppingCart}
              onOrder={onAddItemtoShoppingCart}
              totalQuantity={totalQuantity}
              showShoppingCart={showShoppingCart}
              setShowShoppingCart={setShowShoppingCart}
              color={place.color}
              font={place.font}
            />
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default Menu;

