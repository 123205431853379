import { Form, FormLabel, Button } from "react-bootstrap";
import { useState, useEffect, useRef } from "react";
import { toast } from "react-toastify";

export default function AddVariants({
  variantArray,
  setVariantArray,
  price,
  setPrice,
  languages,
  selectedVariantIndex,
  setSelectedVariantIndex,
  langKey,
}) {
  const [isAdding, setIsAdding] = useState(false);

  const [variants, setVariants] = useState([]);
  const [variantPrice, setVariantPrice] = useState(0);
  const [editVariant, setEditVariant] = useState();
  const [editPrice, setEditPrice] = useState("");
  const [translation, setTranslation] = useState();

  const variantRef = useRef();

  const handleFieldChange = (index, field, value) => {
    if (field === "variant") {
      const updatedVariants = [...variants];
      updatedVariants[index] = value;
      setVariants(updatedVariants);
    } else if (field === "price") {
      setVariantPrice(value);
    }
  };

  const addNewVariant = () => {
    setIsAdding(true);
    setVariants([...variants, ""]);
  };

  const cancelVariant = () => {
    if (isAdding) {
      setIsAdding(false);
      setVariants([]);
      setVariantPrice(0);
    } else if (selectedVariantIndex !== -1) {
      setSelectedVariantIndex(-1);
      setEditVariant("");
      setEditPrice("");
    }
  };

  const handleDeleteVariant = (index) => {
    const c = window.confirm("Are you sure?");
    if (c) {
      setVariantArray((prevArray) => {
        const newVariants = [...prevArray];
        if (newVariants.length > 1) {
          newVariants.splice(index, 1);
          return newVariants;
        } else return null;
      });
    }
  };

  const handleEditChange = (field, value) => {
    if (field === `variant_${langKey}`) {
      setEditVariant((prevState) => ({
        ...prevState,
        [`variant_${langKey}`]: value,
      }));
    } else if (field === "price") {
      setEditPrice(value);
    }
  };

  const translate = async () => {
    try {
      const message = `prevedi mi ${variantRef?.current?.value} na jezike ${languages} i vrati samo ovo:
      variant_bs: prijevod
      variant_en: prijevod
      variant_de: prijevod
      variant_es: prijevod
      variant_fr: prijevod
      variant_it: prijevod
      variant_tr: prijevod
      variant_sa: prijevod
      variant_rs: prijevod
      variant_sr: prijevod
      variant_hr: prijevod
      variant_me: prijevod
      variant_al: prijevod
      variant_sl: prijevod
      variant_nl: prijevod
      variant_at: prijevod
      variant_en_us: prijevod
      `;
      const res = await fetch("https://api.openai.com/v1/chat/completions", {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          Authorization:
            "Bearer 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",
        },
        body: JSON.stringify({
          messages: [{ role: "system", content: message }],
          model: "gpt-3.5-turbo",
        }),
      });

      const json = await res.json();

      const translationList = {};
      json.choices[0].message.content.split("\n\n").forEach((translation) => {
        translation.split("\n").forEach((translation) => {
          const [langCode, value] = translation.split(":");
          translation.trim(" ");
          translationList[langCode.trim()] = value?.trim();
        });
      });
      await setTranslation(translationList);
    } finally {
      toast("Tekst je preveden", { type: "success" });
    }
  };

  const handleSaveVariant = () => {
    if (selectedVariantIndex !== -1) {
      const priceToEdit = parseFloat(editPrice);
      const updatedVariant = {
        ...variantArray[selectedVariantIndex],
        variant_bs: editVariant.variant_bs,
        variant_en: editVariant.variant_en,
        variant_de: editVariant.variant_de,
        variant_es: editVariant.variant_es,
        variant_fr: editVariant.variant_fr,
        variant_it: editVariant.variant_it,
        variant_tr: editVariant.variant_tr,
        variant_sa: editVariant.variant_sa,
        variant_rs: editVariant.variant_rs,
        variant_sr: editVariant.variant_sr,
        variant_hr: editVariant.variant_hr,
        variant_me: editVariant.variant_me,
        variant_al: editVariant.variant_al,
        variant_sl: editVariant.variant_sl,
        variant_nl: editVariant.variant_nl,
        variant_at: editVariant.variant_at,
        variant_en_us: editVariant.variant_en_us,
        price: priceToEdit.toFixed(2),
      };
      const updatedVariants = [...variantArray];
      updatedVariants[selectedVariantIndex] = updatedVariant;

      setVariantArray(updatedVariants);
      setSelectedVariantIndex(-1);
      setEditVariant("");
      setEditPrice("");
    } else {
      if (translation) {
        const priceToEdit = parseFloat(variantPrice);
        const newObject = variants.map(() => ({
          variant_bs: translation.variant_bs,
          variant_en: translation.variant_en,
          variant_de: translation.variant_de,
          variant_es: translation.variant_es,
          variant_fr: translation.variant_fr,
          variant_it: translation.variant_it,
          variant_tr: translation.variant_tr,
          variant_sa: translation.variant_sa,
          variant_rs: translation.variant_rs,
          variant_sr: translation.variant_sr,
          variant_hr: translation.variant_hr,
          variant_me: translation.variant_me,
          variant_al: translation.variant_al,
          variant_sl: translation.variant_sl,
          variant_nl: translation.variant_nl,
          variant_at: translation.variant_at,
          variant_en_us: translation.variant_en_us,
          price: priceToEdit.toFixed(2),
        }));
        setIsAdding(false);
        setVariantArray((prevArray) => {
          if (Array.isArray(prevArray)) {
            return [...prevArray, ...newObject];
          } else {
            return newObject;
          }
        });
        setVariants([]);
        setVariantPrice(0);
      } else {
        const priceToEdit = parseFloat(variantPrice);
        const newObject = variants.map((variant) => ({
          variant_bs: variant,
          price: priceToEdit.toFixed(2),
        }));
        setIsAdding(false);
        setVariantArray((prevArray) => {
          if (Array.isArray(prevArray)) {
            return [...prevArray, ...newObject];
          } else {
            return newObject;
          }
        });
        setVariants([]);
        setVariantPrice(0);
      }
    }
  };

  useEffect(() => {
    if (selectedVariantIndex !== -1) {
      const selectedVariant = variantArray[selectedVariantIndex];
      setEditPrice(selectedVariant?.price || "");
    }
  }, [selectedVariantIndex, variantArray]);

  return (
    <>
      {isAdding ? (
        <div style={{ marginBottom: "10px" }}>
          {variants.map((variant, index) => (
            <>
              <div
                key={index}
                style={{ display: "grid", marginBottom: "10px" }}
              >
                <Form.Group>
                  <Form.Label>Size:</Form.Label>
                  <Form.Control
                    id={`variantInput-${index}`}
                    type="text"
                    ref={variantRef}
                    value={variant}
                    onChange={(e) =>
                      handleFieldChange(index, "variant", e.target.value)
                    }
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Price:</Form.Label>
                  <Form.Control
                    id={`priceInput-${index}`}
                    type="text"
                    value={variantPrice}
                    onChange={(e) =>
                      handleFieldChange(index, "price", e.target.value)
                    }
                  />
                </Form.Group>
              </div>
              <button className="btn btn-primary mr-1" onClick={translate}>
                Translate
              </button>
              <button
                className="btn btn-primary mr-1"
                onClick={handleSaveVariant}
              >
                Save Size
              </button>
              <button className="btn btn-danger" onClick={cancelVariant}>
                Cancel
              </button>
            </>
          ))}
        </div>
      ) : (
        <>
          <div>
            <FormLabel>Size</FormLabel>
          </div>
          <Button
            style={{ marginBottom: "10px", width: "50%" }}
            variant="standard"
            type="submit"
            onClick={addNewVariant}
          >
            Add Size
          </Button>
          {variantArray && variantArray.length > 0 ? (
            selectedVariantIndex === -1 ? (
              <Form.Group>
                <Form.Label>Sizes</Form.Label>
                <ul>
                  {variantArray
                    .sort((a, b) => a.price - b.price)
                    .map((object, index) => (
                      <li
                        key={index}
                        style={{
                          marginBottom: "10px",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            gap: "30px",
                            alignItems: "center",
                            placeContent: "space-between",
                          }}
                        >
                          <div
                            style={{
                              maxWidth: "200px",
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <p>Size: {object?.[`variant_${langKey}`]}</p>
                            <p> Price: {object.price}</p>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              gap: "5px",
                            }}
                          >
                            <button
                              className="btn btn-primary"
                              onClick={() => {
                                setSelectedVariantIndex(index);
                                setEditVariant(object);
                              }}
                            >
                              Edit
                            </button>
                            <button
                              type="submit"
                              className="btn btn-danger"
                              onClick={() => handleDeleteVariant(index)}
                            >
                              Delete
                            </button>
                          </div>
                        </div>
                      </li>
                    ))}
                </ul>
              </Form.Group>
            ) : (
              <div>
                <Form.Group>
                  <Form.Label>Edit Size:</Form.Label>
                  <Form.Control
                    id="editVariantInput"
                    type="text"
                    value={editVariant?.[`variant_${langKey}`]}
                    onChange={(e) =>
                      handleEditChange(`variant_${langKey}`, e.target.value)
                    }
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Edit Price:</Form.Label>
                  <Form.Control
                    id="editPriceInput"
                    type="text"
                    value={editPrice}
                    onChange={(e) => handleEditChange("price", e.target.value)}
                  />
                </Form.Group>
                <div
                  style={{ display: "flex", gap: "5px", marginBottom: "10px" }}
                >
                  <button
                    className="btn btn-primary"
                    onClick={handleSaveVariant}
                  >
                    Update
                  </button>
                  <button className="btn btn-danger" onClick={cancelVariant}>
                    Cancel
                  </button>
                </div>
              </div>
            )
          ) : (
            <Form.Group>
              <Form.Label>Price</Form.Label>
              <Form.Control
                type="number"
                placeholder="Enter Price"
                value={price}
                onChange={(e) => setPrice(e.target.value)}
              />
            </Form.Group>
          )}
        </>
      )}
    </>
  );
}
