import "../index.css";
import React, { useEffect, useContext, createContext, useState } from "react";
import { fetchPlace } from "../apis";
import AuthContext from "../contexts/AuthContext";

const PlaceContext = createContext("");

export function PlaceProvider({ children }) {
  const [place, setPlace] = useState([]);
  const [mainCategories, setMainCategories] = useState([]);
  const auth = useContext(AuthContext);
  const [placeId, setPlaceId] = useState("");

  const onFetchPlace = async () => {
    if (placeId) {
      const json = await fetchPlace(placeId, auth.token);
      if (json) {
        setPlace(json);
        processCategories(json.categories);
      }
    }
  };

  const processCategories = (categories) => {
    const childMap = categories.reduce((map, child) => {
      return {
        ...map,
        [child.id]: {
          ...child,
        },
      };
    }, {});

    const mainCategories = [];
    Object.values(childMap).forEach((child) => {
      if (child.parent_category) {
        if (childMap[child.parent_category]) {
          const parent = childMap[child.parent_category];
          if (!parent.children) {
            parent.children = [];
          }
          parent.children.push(child);
        }
      } else {
        mainCategories.push(child);
      }
    });

    setMainCategories(mainCategories);
  };

  useEffect(() => {
    onFetchPlace();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [placeId]);

  return (
    <PlaceContext.Provider
      value={{ setPlace, place, placeId, setPlaceId, mainCategories }}
    >
      {children}
    </PlaceContext.Provider>
  );
}

export default PlaceContext;
