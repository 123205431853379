import { Button, Form, Tab, Tabs } from "react-bootstrap";
import {
  DE,
  GB,
  ES,
  FR,
  IT,
  TR,
  BA,
  RS,
  HR,
  ME,
  AL,
  SI,
  NL,
  AT,
  SA,
  US,
} from "country-flag-icons/react/3x2";
import React, { useState, useEffect } from "react";
import ImageDropzone from "../containers/ImageDropzone";

const CategoryTabs = ({ langKey, language, category }) => {
  const [name, setName] = useState(category[`name${langKey}`] ?? "");

  useEffect(() => {
    category[`name${langKey}`] = name;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name]);

  return (
    <Form.Group>
      <Form.Label>Category name on {language}</Form.Label>
      <Form.Control
        type="text"
        placeholder={`Category Name on ${language}`}
        value={name}
        defaultValue={name}
        name={`name${langKey}`}
        key={`name${langKey}`}
        onChange={(e) => setName(e.target.value)}
      />
    </Form.Group>
  );
};

export default function CategoryPopover({
  onAddCategory,
  place,
  onUpdateCategory,
  category,
  bgImage,
  setBgImage,
  setCategoryPriority,
  setParentCategory,
  setIsAvailable,
}) {
  const mainCategories = [];

  if (place && place.categories) {
    const data = place.categories;
    const childMap = data.reduce((map, child) => {
      return {
        ...map,
        [child.id]: {
          ...child,
        },
      };
    }, {});

    Object.values(childMap).forEach((child) => {
      if (child.parent_category) {
        if (childMap[child.parent_category]) {
          const parent = childMap[child.parent_category];
          if (!parent.children) {
            parent.children = [];
          }
          parent.children.push(child);
        }
      } else {
        mainCategories.push(child);
      }
    });
  }

  useEffect(() => {
    setCategoryPriority();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setBgImage(category.background_image);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function renderCategories(categories, indentation = "") {
    return categories.map((category) => (
      <>
        <option key={category.id} value={category.id}>
          {`${indentation} ${category.name_bs}`}
        </option>
        {category.children &&
          renderCategories(category.children, `${indentation}-`)}
      </>
    ));
  }

  return (
    <div>
      <Tabs
        id="controlled-tab-example"
        className="mb-3"
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(auto-fill, minmax(30px, 1fr))",
          gap: "10px",
          gridAutoFlow: "row",
          paddingBottom: "10px",
        }}
      >
        <Tab
          eventKey="lang-en"
          title={<GB title="Great Britain" style={{ width: "25px" }}></GB>}
        >
          <CategoryTabs category={category} langKey="_en" language="English" />
        </Tab>
        <Tab
          eventKey="lang-bs"
          title={<BA title="Bosnia" style={{ width: "25px" }}></BA>}
        >
          <CategoryTabs category={category} langKey="_bs" language="Bosnian" />
        </Tab>
        <Tab
          eventKey="lang-de"
          title={<DE title="Germany" style={{ width: "25px" }}></DE>}
        >
          <CategoryTabs category={category} langKey="_de" language="German" />
        </Tab>
        <Tab
          eventKey="lang-es"
          title={<ES title="Spain" style={{ width: "25px" }}></ES>}
        >
          <CategoryTabs category={category} langKey="_es" language="Spanish" />
        </Tab>
        <Tab
          eventKey="lang-fr"
          title={<FR title="France" style={{ width: "25px" }}></FR>}
        >
          <CategoryTabs category={category} langKey="_fr" language="French" />
        </Tab>
        <Tab
          eventKey="lang-it"
          title={<IT title="Italy" style={{ width: "25px" }}></IT>}
        >
          <CategoryTabs category={category} langKey="_it" language="Italian" />
        </Tab>
        <Tab
          eventKey="lang-tr"
          title={<TR title="Turkie" style={{ width: "25px" }}></TR>}
        >
          <CategoryTabs category={category} langKey="_tr" language="Turkish" />
        </Tab>
        <Tab
          eventKey="lang-sa"
          title={<SA title="العربية" style={{ width: "25px" }}></SA>}
        >
          <CategoryTabs category={category} langKey="_sa" language="العربية" />
        </Tab>
        <Tab
          eventKey="lang-rs"
          title={<RS title="Serbian Latinic" style={{ width: "25px" }}></RS>}
        >
          <CategoryTabs
            category={category}
            langKey="_rs"
            language="Serbian Latinic"
          />
        </Tab>
        <Tab
          eventKey="lang-sr"
          title={<RS title="Serbian Cyrillic" style={{ width: "25px" }}></RS>}
        >
          <CategoryTabs
            category={category}
            langKey="_sr"
            language="Serbian Cyrillic"
          />
        </Tab>
        <Tab
          eventKey="lang-hr"
          title={<HR title="Croatia" style={{ width: "25px" }}></HR>}
        >
          <CategoryTabs category={category} langKey="_hr" language="Croatian" />
        </Tab>
        <Tab
          eventKey="lang-me"
          title={<ME title="Montenegro" style={{ width: "25px" }}></ME>}
        >
          <CategoryTabs
            category={category}
            langKey="_me"
            language="Montenegro"
          />
        </Tab>
        <Tab
          eventKey="lang-al"
          title={<AL title="Albania" style={{ width: "25px" }}></AL>}
        >
          <CategoryTabs category={category} langKey="_al" language="Albania" />
        </Tab>
        <Tab
          eventKey="lang-sl"
          title={<SI title="Slovenia" style={{ width: "25px" }}></SI>}
        >
          <CategoryTabs category={category} langKey="_sl" language="Slovenia" />
        </Tab>
        <Tab
          eventKey="lang-nl"
          title={<NL title="Dutch" style={{ width: "25px" }}></NL>}
        >
          <CategoryTabs category={category} langKey="_nl" language="Dutch" />
        </Tab>
        <Tab
          eventKey="lang-at"
          title={<AT title="Austria" style={{ width: "25px" }}></AT>}
        >
          <CategoryTabs category={category} langKey="_al" language="Austria" />
        </Tab>
        <Tab
          eventKey="lang-en_us"
          title={<US title="English" style={{ width: "25px" }}></US>}
        >
          <CategoryTabs
            category={category}
            langKey="_en_us"
            language="English"
          />
        </Tab>
      </Tabs>
      <Form.Label> Category Background Image</Form.Label>
      <ImageDropzone
        style={{ width: "100%", height: "100%" }}
        value={bgImage}
        onChange={setBgImage}
      />
      <Form.Label>Choose parent category</Form.Label>
      <Form.Control
        as="select"
        value={category.parent_category}
        onChange={(e) => setParentCategory(e.target.value)}
      >
        <>
          <option value={null}>{null}</option>
          {mainCategories && renderCategories(mainCategories)}
        </>
      </Form.Control>
      <Form.Label>Priority</Form.Label>
      <Form.Control
        className="priority"
        type="number"
        placeholder="If you set priority to 1, it will show on the top"
        defaultValue={category.priority}
        onChange={(e) => setCategoryPriority(e.target.value)}
      />
      {category.is_available !== undefined && (
        <Form.Check
          style={{ marginTop: "20px", marginBottom: "20px" }}
          type="checkbox"
          label="Is available?"
          defaultChecked={category.is_available}
          onChange={(e) => setIsAvailable(e.target.checked)}
        />
      )}
      <Button
        variant="standard"
        block
        onClick={category.id ? onUpdateCategory : onAddCategory}
      >
        {category.id ? "Update category" : "+ Add category"}
      </Button>
    </div>
  );
}
