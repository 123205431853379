import React, { useEffect, useState, useContext } from "react";
import styled from "styled-components";
import LanguageContext from "../contexts/LanguageContext";
import cancel from "../photos/cancel.png";
import izaberi from "../photos/IzaberiIkonica.svg";
import prilozi from "../photos/salata.svg";
import shoppingCartImage from "../photos/shopping-cart.svg";
import Translation from "./Translation";
import { IoIosAddCircle } from "react-icons/io";
import { FaCheck } from "react-icons/fa";
import { IoArrowDownCircleSharp, IoArrowUpCircleSharp } from "react-icons/io5";
import MenuItem from "./MenuItem";
import PlaceContext from "../contexts/PlaceContext";

export default function Item({
  item,
  font,
  onOrder,
  onDone,
  ordering,
  shakeCart,
  setShakeCart,
  shoppingCart,
  color,
  setSelectedItem,
  previousItem,
  setPreviousItem,
}) {
  const [openSize, setOpenSize] = useState(false);
  const [openSideDish, setOpenSideDish] = useState(false);
  const { place } = useContext(PlaceContext);

  const { language } = useContext(LanguageContext);
  const { translate } = Translation({ language });
  const itemVariants = item.variants;
  const itemSideDish = item.side_dish;
  const [openFeaturedItems, setOpenFeaturedItems] = useState(false);
  const waiterLogged = localStorage.getItem("waiterLogged");

  const [addedVariant, setAddedVariant] = useState(
    itemVariants &&
      itemVariants.length > 0 && {
        price: itemVariants?.[0].price,
        [`variant_${language}`]: itemVariants?.[0][`variant_${language}`],
      }
  );
  const [addedSideDishes, setAddedSideDishes] = useState([]);

  const handleSideDishSelection = (dish) => {
    const isDishSelected = addedSideDishes.some(
      (selectedDish) =>
        selectedDish[`side_dish_${language}`] === dish[`side_dish_${language}`]
    );

    if (isDishSelected) {
      // If the dish is already selected, remove it
      const updatedDishes = addedSideDishes.filter(
        (selectedDish) =>
          selectedDish[`side_dish_${language}`] !==
          dish[`side_dish_${language}`]
      );
      setAddedSideDishes(updatedDishes);
    } else {
      // If the dish is not selected, add it
      const updatedDishes = [
        ...addedSideDishes,
        {
          price: dish.price,
          [`side_dish_${language}`]: dish[`side_dish_${language}`],
        },
      ];
      setAddedSideDishes(updatedDishes);
    }
  };

  let desc = item["description_" + language];

  const Div = styled.div`
    b, p {
      ${({ font }) => font && `font-family: ${font};`}
    },
  `;

  const [finalPrice, setFinalPrice] = useState(0);

  useEffect(() => {
    if (itemVariants !== null && addedSideDishes.length > 0) {
      const currentPrice = addedSideDishes.reduce(
        (acc, dish) => acc + parseFloat(dish.price),
        parseFloat(addedVariant.price)
      );
      setFinalPrice(currentPrice);
    } else if (itemVariants && itemVariants.length > 0) {
      item.price = addedVariant?.price;
      setFinalPrice(addedVariant?.price);
    } else if (itemSideDish) {
      const currentPrice = addedSideDishes.reduce(
        (acc, dish) => acc + parseFloat(dish.price),
        parseFloat(item.price)
      );
      setFinalPrice(currentPrice);
    } else {
      setFinalPrice(item.price);
    }
  }, [addedSideDishes, addedVariant, itemVariants, itemSideDish, item]);

  const totalPrice = parseFloat(finalPrice);
  const formattedTotalPrice = totalPrice.toFixed(2);
  return (
    <>
      <div
        className={`${openSize || openSideDish ? "d-none" : ""}`}
        style={{
          backgroundColor: "white",
          borderRadius: "45px",
          display: "flex",
          flexDirection: "column",
          maxHeight: "70vh",
          position: "relative",
          overflow: "scroll",
        }}
      >
        <img
          alt="close-modal"
          className="cancel-icon"
          onClick={() => onDone()}
          src={cancel}
        ></img>
        <div className="oneitem-root">
          <img className="item-img" alt="item-pic" src={item.image}></img>
        </div>
        <Div
          font={font}
          className={desc ? "oneitem-content" : "oneitem-content-without-desc"}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "start",
                gap: "5px",
                width: "60%",
              }}
            >
              <b className="modalItemName">{item["name_" + language]}</b>
              {item.tag?.name && item.tag?.name_en !== "Select Tag" && (
                <div
                  style={{
                    background: item.tag?.color
                      ? item.tag?.color
                      : "transparent",
                    paddingLeft: "10px",
                    paddingRight: "10px",
                    color: "white",
                    gap: "5px",
                    fontSize: "12px",
                    borderRadius: "6px",
                    lineHeight: "12px",
                    paddingBottom: "3px",
                    paddingTop: "3px",
                  }}
                  className="d-flex align-items-center justify-content-between "
                >
                  {item?.tag?.["name_" + language]}
                  <img
                    src={item.tag?.icon}
                    alt="icon"
                    style={{ width: "12px", height: "12px" }}
                  />
                </div>
              )}
            </div>
            <div style={{ display: "flex", alignItems: "center", gap: "17px" }}>
              <b className={item.price !== "0.00" ? "" : "d-none"}>
                {totalPrice > 0
                  ? totalPrice.toFixed(2)
                  : addedVariant && addedVariant.length > 0
                    ? addedVariant.price
                    : item.price}
                {place.currency}
              </b>
            </div>
          </div>
          <p
            className="modalItemDescription"
            dangerouslySetInnerHTML={{
              __html: item["description_" + language],
            }}
          ></p>
        </Div>
      </div>
      {openSize && (
        <div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              position: "absolute",
              top: "13%",
              left: "50%",
              transform: "translate(-50%, 0%)",
              width: "90%",
              height: "80%",
              overflow: "scroll",
            }}
          >
            {itemVariants &&
              itemVariants.map((variant) => {
                return (
                  <button
                    key={variant[`variant_${language}`]}
                    className={
                      variant[`variant_${language}`] ==
                      addedVariant?.[`variant_${language}`]
                        ? "activeVariantButton"
                        : "variantButton"
                    }
                    onClick={() =>
                      setAddedVariant({
                        price: variant.price,
                        [`variant_${language}`]: variant[`variant_${language}`],
                      })
                    }
                    value={addedVariant?.price}
                  >
                    {variant[`variant_${language}`]}{" "}
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "8px",
                      }}
                    >
                      {variant.price && variant.price !== "0.00" && (
                        <span style={{ fontSize: "13px" }}>
                          {variant.price} {place.currency}
                        </span>
                      )}
                      <span>
                        {JSON.stringify(addedVariant) ===
                        JSON.stringify(variant) ? (
                          <span className="checkIcon">
                            <FaCheck size={15} color="white" />
                          </span>
                        ) : (
                          <IoIosAddCircle size={25} color="#14e714" />
                        )}
                      </span>
                    </div>
                  </button>
                );
              })}
          </div>

          <button
            onClick={() => setOpenSize(!openSize)}
            style={{ bottom: "-50px" }}
            className="potvrdiButton"
          >
            {ordering || waiterLogged === "true" ? "POTVRDI" : "NAZAD"}
          </button>
        </div>
      )}
      {openSideDish && (
        <div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              position: "absolute",
              top: "0%",
              left: "50%",
              transform: "translate(-50%, 0%)",
              width: "90%",
              height: "90%",
              overflow: "scroll",
            }}
          >
            {item.side_dish &&
              item.side_dish?.map((dish) => {
                const isSelected = addedSideDishes.some(
                  (selectedDish) =>
                    selectedDish[`side_dish_${language}`] ===
                    dish[`side_dish_${language}`]
                );
                return (
                  <button
                    key={dish[`side_dish_${language}`]}
                    className={`${
                      isSelected ? "activeVariantButton" : "variantButton"
                    }`}
                    onClick={() => handleSideDishSelection(dish)}
                    value={addedSideDishes?.price}
                  >
                    {dish[`side_dish_${language}`]}{" "}
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "8px",
                      }}
                    >
                      {dish.price && dish.price !== "0.00" && (
                        <span style={{ fontSize: "13px" }}>
                          {dish.price} {place.currency}
                        </span>
                      )}
                      <span>
                        {isSelected ? (
                          <span className="checkIcon">
                            <FaCheck size={15} color="white" />
                          </span>
                        ) : (
                          <IoIosAddCircle size={25} color="#14e714" />
                        )}
                      </span>
                    </div>
                  </button>
                );
              })}
          </div>
          <button
            onClick={() => setOpenSideDish(!openSideDish)}
            style={{ bottom: "-50px" }}
            className="potvrdiButton"
          >
            {ordering || waiterLogged === "true" ? "POTVRDI" : "NAZAD"}
          </button>
        </div>
      )}
      <div
        className={`${openSize || openSideDish ? "d-none" : "variants-div"}`}
      >
        {itemVariants && itemVariants.length > 0 && (
          <div
            style={{
              width: "33%",
              maxHeight: "116px",
              textAlign: "center",
            }}
          >
            <button
              onClick={() => setOpenSize(!openSize)}
              style={{
                border: "4px solid #31c16cad",
                borderRadius: "100%",
                padding: "10px",
                backgroundColor: "transparent",
              }}
            >
              <img alt="choose" src={izaberi} width={40} height={40}></img>
            </button>
            <p className="text-white pt-2 break-all">
              {translate("choose").toUpperCase()}
            </p>
          </div>
        )}
        {item.side_dish?.length > 0 && (
          <div style={{ width: "33%", maxHeight: "116px" }}>
            <button
              onClick={() => setOpenSideDish(!openSideDish)}
              style={{
                border: "4px solid #31c1b8",
                borderRadius: "100%",
                padding: "10px",
                backgroundColor: "transparent",
              }}
            >
              <img alt="side-dishes" src={prilozi} width={40} height={40}></img>
            </button>
            <p className="text-white pt-2 break-all">
              {translate("sideDishes")}
            </p>
          </div>
        )}
        {item?.price !== "0.00"
          ? (ordering || waiterLogged === "true") && (
              <div style={{ width: "33%", maxHeight: "116px" }}>
                <button
                  onClick={() => {
                    onOrder({
                      ...item,
                      price: formattedTotalPrice,
                      variants: addedVariant?.[`variant_${language}`],
                      side_dish: addedSideDishes,
                    });
                    setAddedSideDishes([]);
                    setShakeCart(true);
                    setTimeout(() => {
                      setShakeCart(false);
                      onDone();
                    }, 500);
                  }}
                  style={{
                    border: "4px solid #1ae26c",
                    borderRadius: "100%",
                    padding: "10px",
                    backgroundColor: "transparent",
                    animation: shakeCart ? "shake 0.5s" : "none",
                  }}
                >
                  <img
                    alt="shopping-cart"
                    src={shoppingCartImage}
                    width={40}
                    height={40}
                  ></img>
                </button>
                <p className="text-white pt-2 break-all">
                  {translate("addToCart")}
                </p>
              </div>
            )
          : ""}
      </div>
      {item.featured_items?.length > 0 && !previousItem && (
        <div className={`${openSize || openSideDish ? "d-none" : ""}`}>
          <div
            onClick={() => setOpenFeaturedItems(!openFeaturedItems)}
            style={{ gap: "10px" }}
            className="d-flex justify-content-center align-items-center cursor-pointer"
          >
            <h2
              style={{ fontSize: "15px", margin: 0 }}
              className="text-white text-uppercase"
            >
              {translate("weRecommend")}
            </h2>
            {openFeaturedItems ? (
              <IoArrowUpCircleSharp
                style={{ width: "20px", height: "20px" }}
                color="white"
              />
            ) : (
              <IoArrowDownCircleSharp
                style={{ width: "20px", height: "20px" }}
                color="white"
              />
            )}
          </div>
          <div
            className={`transition-container ${
              openFeaturedItems ? "open" : ""
            }`}
            style={{
              textAlign: "-webkit-center",
              display: "grid",
              gap: "15px",
              marginTop: "20px",
            }}
          >
            {openFeaturedItems &&
              item.featured_items.map(
                (featuredItem) =>
                  featuredItem.featured_item.is_available && (
                    <div
                      onClick={() => {
                        setSelectedItem(featuredItem.featured_item);
                        setPreviousItem(item);
                      }}
                      key={featuredItem.id}
                      style={{ position: "relative" }}
                    >
                      <div
                        style={{
                          width: "82%",
                          cursor: "pointer",
                        }}
                      >
                        <MenuItem
                          key={featuredItem.featured_item.id}
                          item={{
                            ...featuredItem.featured_item,
                            quantity: shoppingCart[featuredItem.id]?.quantity,
                          }}
                          color={color}
                          font={font}
                        />
                      </div>
                    </div>
                  )
              )}
          </div>
        </div>
      )}
    </>
  );
}

