import { Button, Spinner } from "react-bootstrap";
import { React, useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import AuthContext from "../contexts/AuthContext";
import AdminDesign from "../photos/adminPanelDesign.jpg";
import { MdEmail } from "react-icons/md";
import { IoMdKey } from "react-icons/io";

const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const navigate = useNavigate();
  const auth = useContext(AuthContext);

  useEffect(() => {
    if (auth.token) {
      navigate("/places");
    }
  });

  const onClick = () => {
    auth.signIn(username, password, () => navigate("/places"));
  };

  return (
    <div className="container-fluid p-0 m-0 bg-white">
      <div className="row p-0 m-0">
        <div className="col m-0 p-0 d-none d-sm-none d-lg-block">
          <img
            alt="AdminDesign"
            style={{ height: "100vh", width: "auto", maxWidth: "100%" }}
            src={AdminDesign}
          />
        </div>
        <div className="col d-flex m-0 p-0">
          <div
            className="d-flex flex-column pt-4 pb-4 p-lg-0"
            style={{
              gap: "20px",
              alignSelf: "center",
              margin: "20px 30px",
              padding: "auto",
            }}
          >
            <h1 style={{ fontWeight: "350" }} className="pt-4 pt-lg-0 pb-4">
              Zdravo,
            </h1>
            <h2 style={{ fontWeight: "350" }}>
              Dobro došli na <br />
              <span style={{ fontWeight: "400", color: "#3a4d93" }}>
                Admin Panel
              </span>
            </h2>
            <h5 style={{ fontWeight: "200", color: "gray" }}>
              Unesi svoje podatke za pristup svom biznisu
            </h5>
            <div className="d-flex flex-column">
              <span
                style={{
                  fontSize: "12px",
                  marginTop: "5px",
                  marginBottom: "5px",
                  fontWeight: "400",
                  color: "gray",
                }}
              >
                EMAIL ADRESA
              </span>
              <div
                style={{
                  backgroundColor: "#f2f2f285",
                  display: "flex",
                  width: "100%",
                }}
              >
                <span
                  style={{
                    marginLeft: "10px",
                    alignSelf: "center",
                  }}
                >
                  <MdEmail size={24} />
                </span>
                <input
                  style={{
                    paddingLeft: "15px",
                    height: "40px",
                    maxWidth: "100%",
                    backgroundColor: "#f2f2f285",
                    border: "0px",
                    borderRadius: 0,
                  }}
                  type="text"
                  class="form-control"
                  placeholder="info@podrska.ba"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                />
              </div>
            </div>
            <div className="d-flex flex-column">
              <span
                style={{
                  fontSize: "12px",
                  marginTop: "5px",
                  marginBottom: "5px",
                  fontWeight: "400",
                  color: "gray",
                }}
              >
                LOZINKA
              </span>
              <div
                style={{
                  backgroundColor: "#f2f2f285",
                  display: "flex",
                  width: "100%",
                }}
              >
                <span
                  style={{
                    marginLeft: "10px",
                    alignSelf: "center",
                  }}
                >
                  <IoMdKey size={24} />
                </span>
                <input
                  style={{
                    paddingLeft: "15px",
                    height: "40px",
                    maxWidth: "100%",
                    backgroundColor: "#f2f2f285",
                    border: "0px",
                    borderRadius: 0,
                  }}
                  type="password"
                  class="form-control"
                  placeholder="Unesi svoju lozinku"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
            </div>
            <Button
              style={{
                maxWidth: "400px",
                borderRadius: 0,
                backgroundColor: "#3a4d93",
                border: "#3a4d93",
              }}
              block
              onClick={onClick}
              disabled={auth.loading}
            >
              {auth.loading ? (
                <Spinner
                  variant="standard"
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              ) : (
                "PRIJAVI SE"
              )}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
