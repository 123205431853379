import { IoMdArrowBack } from "react-icons/io";
import { AiOutlineDelete, AiOutlineQrcode } from "react-icons/ai";
import { RiFileList3Line } from "react-icons/ri";
import { FiSettings } from "react-icons/fi";
import { Row, Col, Button, Modal, Form, Tabs, Tab } from "react-bootstrap";
import { useParams, useNavigate } from "react-router-dom";
import React, { useEffect, useState, useContext } from "react";
import styled from "styled-components";
import { BiEdit } from "react-icons/bi";
import {
  DE,
  GB,
  ES,
  FR,
  IT,
  TR,
  BA,
  RS,
  HR,
  ME,
  AL,
  SI,
  NL,
  AT,
  US,
  SA,
} from "country-flag-icons/react/3x2";

import {
  fetchPlace,
  removePlace,
  removeCategory,
  removeMenuItem,
  updatePlace,
  updateCategory,
  fetchMenuItemsByPlace,
  fetchTags,
} from "../apis";
import AuthContext from "../contexts/AuthContext";
import MainLayout from "../layouts/MainLayout";
import MenuItemForm from "../containers/MenuItemForm";
import MenuItem from "../components/MenuItem";
import QRCodeModal from "../components/QRCodeModal";
import CategoryPopover from "../components/CategoryPopover";
import PlaceContext from "../contexts/PlaceContext";

const Panel = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.05);
`;

const Place = () => {
  const { place, setPlace } = useContext(PlaceContext);
  const [menuItemFormShow, setMenuItemFormShow] = useState(false);
  const [tags, setTags] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [featuredItemOptions, setFeaturedItemOptions] = useState([]);
  const [qrCode, setQrCode] = useState(false);
  const [editCategoryModal, setEditCategoryModal] = useState(false);
  const [editCategory, setEditCategory] = useState([]);
  const [bgImage, setBgImage] = useState(editCategory.background_image);
  const [parent_category, setParentCategory] = useState(
    editCategory.parent_category
  );

  const [tableCategories, setTableCategories] = useState([]);
  const [numberOfTables, setNumberOfTables] = useState(1);
  const [categoryPriority, setCategoryPriority] = useState();
  const [name, setName] = useState(place.name_bs || place.name_en);
  const [nameModal, setNameModal] = useState(false);

  const showModal = () => setMenuItemFormShow(true);
  const hideModal = () => setMenuItemFormShow(false);

  const showNameModal = () => setNameModal(true);
  const hideNameModal = () => setNameModal(false);

  const showQRModal = () => setQrCode(true);
  const hideQRModal = () => setQrCode(false);

  const showEditModal = () => setEditCategoryModal(true);
  const hideEditModal = () => setEditCategoryModal(false);

  const auth = useContext(AuthContext);
  const params = useParams();
  const navigate = useNavigate();

  const onBack = () => navigate("/places");

  const Container = styled.div`
    opacity: ${({ active }) => (active ? 1 : 0.6)};
  `;

  const [isAvailable, setIsAvailable] = useState(editCategory.is_available);
  const mainCategories = [];

  if (place && place.categories) {
    const data = place.categories;
    const childMap = data.reduce((map, child) => {
      return {
        ...map,
        [child.id]: {
          ...child,
        },
      };
    }, {});

    Object.values(childMap).forEach((child) => {
      if (child.parent_category) {
        if (childMap[child.parent_category]) {
          const parent = childMap[child.parent_category];
          if (!parent.children) {
            parent.children = [];
          }
          parent.children.push(child);
        }
      } else {
        mainCategories.push(child);
      }
    });
  }

  const fetchMenuItemsForThisPlace = async (placeId) => {
    const json = await fetchMenuItemsByPlace(placeId, auth.token);
    if (json) {
      setFeaturedItemOptions(json);
    }
  };

  const fetchAllTags = async () => {
    const json = await fetchTags(auth.token);
    if (json) {
      setTags(json);
    }
  };

  const onFetchPlace = async () => {
    const json = await fetchPlace(params.id, auth.token);
    if (json) {
      setPlace(json);
      setTableCategories(json.tables_json);
      setNumberOfTables(json.number_of_tables);
      fetchMenuItemsForThisPlace(json.id);
      localStorage.setItem("place", JSON.stringify(json));
    }
  };

  const onRemovePlace = () => {
    const c = window.confirm("Are you sure?");
    if (c) {
      removePlace(params.id, auth.token).then(onBack);
    }
  };

  const onRemoveCategory = (id) => {
    const c = window.confirm("Are you sure?");
    if (c) {
      removeCategory(id, auth.token).then(onFetchPlace);
    }
  };

  const onEditCategory = (category) => {
    setEditCategory(category);
    showEditModal();
  };
  const onUpdateCategory = () => {
    updateCategory(
      editCategory.id,
      {
        name_en: editCategory.name_en,
        name_bs: editCategory.name_bs,
        name_de: editCategory.name_de,
        name_es: editCategory.name_es,
        name_fr: editCategory.name_fr,
        name_it: editCategory.name_it,
        name_tr: editCategory.name_tr,
        name_sa: editCategory.name_sa,
        name_rs: editCategory.name_rs,
        name_sr: editCategory.name_sr,
        name_hr: editCategory.name_hr,
        name_me: editCategory.name_me,
        name_al: editCategory.name_al,
        name_sl: editCategory.name_sl,
        name_nl: editCategory.name_nl,
        name_at: editCategory.name_at,
        name_en_us: editCategory.name_en_us,
        background_image: bgImage,
        parent_category: parent_category,
        priority: categoryPriority,
        is_available: isAvailable,
      },
      auth.token
    ).then(onFetchPlace);
    hideEditModal();
  };

  const onRemoveMenuItem = (id) => {
    const c = window.confirm("Are you sure?");
    if (c) {
      removeMenuItem(id, auth.token).then(onFetchPlace);
    }
  };

  const onUpdatePlace = async () => {
    await updatePlace(
      place.id,
      {
        number_of_tables: numberOfTables,
        tables_json: tableCategories,
      },
      auth.token
    ).then((json) => {
      if (json) {
        setPlace(json);
        localStorage.setItem("place", JSON.stringify(json));
      }
    });
  };

  const onUpdateName = async () => {
    await updatePlace(
      place.id,
      {
        name_en: name.name_en,
        name_bs: name.name_bs,
        name_de: name.name_de,
        name_es: name.name_es,
        name_fr: name.name_fr,
        name_it: name.name_it,
        name_tr: name.name_tr,
        name_sa: name.name_sa,
        name_rs: name.name_rs,
        name_sr: name.name_sr,
        name_hr: name.name_hr,
        name_me: name.name_me,
        name_al: name.name_al,
        name_sl: name.name_sl,
        name_nl: name.name_nl,
        name_at: name.name_at,
        name_en_us: name.name_en_us,
      },
      auth.token
    ).then((json) => {
      if (json) {
        setPlace(json);
      }
    });
  };

  const onChangeName = (name) => {
    setName(name);
    showNameModal();
  };

  const updateName = () => {
    onUpdateName();
    hideNameModal();
  };

  useEffect(() => {
    onFetchPlace();
    fetchAllTags();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const sortCategories = (categories) => {
    return !!categories
      ? categories.sort((a, b) => a.priority - b.priority)
      : [];
  };

  const NameTabs = ({ langKey, language, place }) => {
    const [name, setName] = useState(place[`name${langKey}`] ?? "");

    useEffect(() => {
      place[`name${langKey}`] = name;
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [name]);

    return (
      <Form.Group>
        <Form.Label>Place name on {language}</Form.Label>
        <Form.Control
          type="text"
          placeholder={`Place Name on ${language}`}
          value={name}
          defaultValue={name}
          name={`name${langKey}`}
          key={`name${langKey}`}
          onChange={(e) => setName(e.target.value)}
        />
      </Form.Group>
    );
  };

  const CategorySection = ({ category }) => (
    <div key={category.id} className="mb-5">
      <Container
        active={category.is_available}
        className="align-items-center justify-content-between mb-4 d-flex"
      >
        <h4
          style={{
            backgroundImage: `url(${category.background_image})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundBlendMode: "darken",
            backgroundColor: "rgba(0,0,0, 0.3)",
          }}
          className="mb-0 mr-2 oneCategory"
        >
          <b style={{ color: "white" }}>{category.name_bs}</b>
        </h4>
        {/* <button className='categoryButton' key={category.id}
                    onClick={() => handleClick([category])}
                  >
                    <p style={{ fontWeight: 'bold', margin: '0px' }}>Edit {category.name} items</p>
                  </button> */}
        <div className="d-flex">
          <Button variant="link" onClick={() => onEditCategory(category)}>
            <BiEdit size={25} />
          </Button>
          <Button variant="link" onClick={() => onRemoveCategory(category.id)}>
            <AiOutlineDelete size={25} color="red" />
          </Button>
        </div>
      </Container>
      <div className="d-flex flex-column mb-4" style={{ gap: "20px" }}>
        {category?.menu_items.map((item) => (
          <MenuItem
            key={item.id}
            item={item}
            onEdit={() => {
              setSelectedItem(item);
              showModal();
            }}
            onRemove={() => onRemoveMenuItem(item.id)}
          />
        ))}
      </div>
      {/* {category.children
        ? category.children
            .sort((a, b) => a.priority - b.priority)
            .map((child) => <CategorySection key={child.id} category={child} />)
        : []} */}
      {sortCategories(category.children)?.map((child) => (
        <CategorySection key={child.id} category={child} />
      ))}
    </div>
  );

  return (
    <MainLayout>
      <Row>
        <Col lg={12}>
          <div className="mb-4">
            <div className="d-flex justify-content-between align-items-center mb-4">
              <Button variant="link" onClick={onBack}>
                <IoMdArrowBack size={25} color="black" />
              </Button>
              <h3 className="mb-0 ml-2 mr-2">
                {place.name_bs || place.name_en}
              </h3>
              <div className="d-flex">
                <Button variant="link" onClick={() => onChangeName(place)}>
                  <BiEdit size={25} />
                </Button>
                <Button variant="link" onClick={onRemovePlace}>
                  <AiOutlineDelete size={25} color="red" />
                </Button>
              </div>
            </div>
            {/* <button onClick={addTags}>Dodaj tag</button> */}
            <Button variant="link" onClick={showQRModal}>
              <AiOutlineQrcode size={25} />
            </Button>
            <Button variant="link" href={`/places/${params.id}/orders`}>
              <RiFileList3Line size={25} />
            </Button>
            <Button variant="link" href={`/places/${params.id}/settings`}>
              <FiSettings size={25} />
            </Button>
          </div>
        </Col>

        <Col md={4}>
          <Panel>
            <MenuItemForm
              place={place}
              onDone={() => onFetchPlace()}
              categoryPriority={categoryPriority}
              setCategoryPriority={setCategoryPriority}
              bgImage={bgImage}
              setBgImage={setBgImage}
              item={[]}
              mainCategories={mainCategories}
              categoryIsAvailable={isAvailable}
              setCategoryIsAvailable={setIsAvailable}
              featuredItemOptions={featuredItemOptions}
              tags={tags}
            />
          </Panel>
        </Col>
        <Col md={8}>
          {sortCategories(mainCategories)?.map((category) => (
            <CategorySection key={category.id} category={category} />
          ))}
        </Col>
      </Row>

      <Modal show={menuItemFormShow} onHide={hideModal} centered>
        <div style={{ backgroundColor: "white" }}>
          <Modal.Body>
            <h4 className="text-center">Menu Item</h4>
            <MenuItemForm
              place={place}
              onDone={() => {
                onFetchPlace();
                hideModal();
              }}
              setCategoryPriority={setCategoryPriority}
              setBgImage={setBgImage}
              item={selectedItem}
              mainCategories={mainCategories}
              featuredItemOptions={featuredItemOptions}
              tags={tags}
            />
          </Modal.Body>
        </div>
      </Modal>
      <Modal show={editCategoryModal} onHide={hideEditModal} centered>
        <div style={{ backgroundColor: "white" }}>
          <Modal.Body>
            <h4 className="text-center">Edit Category</h4>
            <CategoryPopover
              onAddCategory={null}
              onUpdateCategory={onUpdateCategory}
              setCategory={setEditCategory}
              category={editCategory}
              bgImage={bgImage}
              setBgImage={setBgImage}
              categoryPriority={categoryPriority}
              parent_category={parent_category}
              setParentCategory={setParentCategory}
              setCategoryPriority={setCategoryPriority}
              place={place}
              isAvailable={isAvailable}
              setIsAvailable={setIsAvailable}
            />
          </Modal.Body>
        </div>
      </Modal>

      {
        // NAME MODAL
      }

      <Modal show={nameModal} onHide={hideNameModal} centered>
        <div style={{ backgroundColor: "white" }}>
          <Modal.Body>
            <Tabs
              id="controlled-tab-example"
              className="mb-3"
              style={{
                display: "grid",
                gridTemplateColumns: "repeat(auto-fill, minmax(30px, 1fr))",
                gap: "10px",
                gridAutoFlow: "row",
                paddingBottom: "10px",
              }}
            >
              <Tab
                eventKey="lang-en"
                title={
                  <GB title="Great Britain" style={{ width: "25px" }}></GB>
                }
              >
                <NameTabs place={name} langKey="_en" language="English" />
              </Tab>
              <Tab
                eventKey="lang-bs"
                title={<BA title="Bosnia" style={{ width: "25px" }}></BA>}
              >
                <NameTabs place={name} langKey="_bs" language="Bosnian" />
              </Tab>
              <Tab
                eventKey="lang-de"
                title={<DE title="Germany" style={{ width: "25px" }}></DE>}
              >
                <NameTabs place={name} langKey="_de" language="German" />
              </Tab>
              <Tab
                eventKey="lang-es"
                title={<ES title="Spain" style={{ width: "25px" }}></ES>}
              >
                <NameTabs place={name} langKey="_es" language="Spanish" />
              </Tab>
              <Tab
                eventKey="lang-fr"
                title={<FR title="France" style={{ width: "25px" }}></FR>}
              >
                <NameTabs place={name} langKey="_fr" language="French" />
              </Tab>
              <Tab
                eventKey="lang-it"
                title={<IT title="Italy" style={{ width: "25px" }}></IT>}
              >
                <NameTabs place={name} langKey="_it" language="Italian" />
              </Tab>
              <Tab
                eventKey="lang-tr"
                title={<TR title="Turkie" style={{ width: "25px" }}></TR>}
              >
                <NameTabs place={name} langKey="_tr" language="Turkish" />
              </Tab>
              <Tab
                eventKey="lang-sa"
                title={<SA title="العربية" style={{ width: "25px" }}></SA>}
              >
                <NameTabs place={name} langKey="_sa" language="العربية" />
              </Tab>
              <Tab
                eventKey="lang-rs"
                title={
                  <RS title="Serbian Latinic" style={{ width: "25px" }}></RS>
                }
              >
                <NameTabs
                  place={name}
                  langKey="_rs"
                  language="Serbian Latinic"
                />
              </Tab>
              <Tab
                eventKey="lang-sr"
                title={
                  <RS title="Serbian Cyrillic" style={{ width: "25px" }}></RS>
                }
              >
                <NameTabs
                  place={name}
                  langKey="_sr"
                  language="Serbian Cyrillic"
                />
              </Tab>
              <Tab
                eventKey="lang-hr"
                title={<HR title="Croatia" style={{ width: "25px" }}></HR>}
              >
                <NameTabs place={name} langKey="_hr" language="Croatian" />
              </Tab>
              <Tab
                eventKey="lang-me"
                title={<ME title="Montenegro" style={{ width: "25px" }}></ME>}
              >
                <NameTabs place={name} langKey="_me" language="Montenegro" />
              </Tab>
              <Tab
                eventKey="lang-al"
                title={<AL title="Albania" style={{ width: "25px" }}></AL>}
              >
                <NameTabs place={name} langKey="_al" language="Albania" />
              </Tab>
              <Tab
                eventKey="lang-sl"
                title={<SI title="Slovenia" style={{ width: "25px" }}></SI>}
              >
                <NameTabs place={name} langKey="_sl" language="Slovenia" />
              </Tab>
              <Tab
                eventKey="lang-nl"
                title={<NL title="Dutch" style={{ width: "25px" }}></NL>}
              >
                <NameTabs place={name} langKey="_nl" language="Dutch" />
              </Tab>
              <Tab
                eventKey="lang-at"
                title={<AT title="Austria" style={{ width: "25px" }}></AT>}
              >
                <NameTabs place={name} langKey="_al" language="Austria" />
              </Tab>
              <Tab
                eventKey="lang-en_us"
                title={<US title="English" style={{ width: "25px" }}></US>}
              >
                <NameTabs place={name} langKey="_en_us" language="English" />
              </Tab>
            </Tabs>

            <Button
              className="mt-2"
              variant="standard"
              block
              onClick={updateName}
            >
              {"Update place name"}
            </Button>
          </Modal.Body>
        </div>
      </Modal>

      <QRCodeModal
        show={qrCode}
        onHide={hideQRModal}
        place={place}
        onDone={() => onFetchPlace()}
        centered
        onUpdatePlace={onUpdatePlace}
        tableCategories={tableCategories}
        setTableCategories={setTableCategories}
        numberOfTables={numberOfTables}
        setNumberOfTables={setNumberOfTables}
      />
    </MainLayout>
  );
};

export default Place;

