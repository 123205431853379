import { Form, FormLabel, Button } from "react-bootstrap";
import { useState, useEffect, useRef } from "react";
import { toast } from "react-toastify";

export default function AddSideDish({
  dishArray,
  setDishArray,
  selectedDishIndex,
  setSelectedDishIndex,
  langKey,
  languages,
}) {
  const [isAdding, setIsAdding] = useState(false);
  const [sideDishes, setSideDishes] = useState([]);
  const [price, setPrice] = useState(0);
  const [editDishes, setEditDishes] = useState();
  const [editPrice, setEditPrice] = useState("");
  const [translation, setTranslation] = useState();

  const sideDishRef = useRef();

  const handleFieldChange = (index, field, value) => {
    if (field === "side_dish") {
      const updatedDishes = [...sideDishes];
      updatedDishes[index] = value;
      setSideDishes(updatedDishes);
    } else if (field === "price") {
      setPrice(value);
    }
  };

  const addNewDish = () => {
    setIsAdding(true);
    setSideDishes([...sideDishes, ""]);
  };

  const cancelDish = () => {
    if (isAdding) {
      setIsAdding(false);
      setSideDishes([]);
      setPrice(0);
    } else if (selectedDishIndex !== -1) {
      setSelectedDishIndex(-1);
      setEditDishes("");
      setEditPrice("");
    }
  };

  const handleDeleteDish = (index) => {
    const c = window.confirm("Are you sure?");
    if (c) {
      setDishArray((prevArray) => {
        const newDish = [...prevArray];
        if (newDish.length > 1) {
          newDish.splice(index, 1);
          return newDish;
        } else return null;
      });
    }
  };

  const handleEditChange = (field, value) => {
    if (field === `side_dish_${langKey}`) {
      setEditDishes((prevState) => ({
        ...prevState,
        [`side_dish_${langKey}`]: value,
      }));
    } else if (field === "price") {
      setEditPrice(value);
    }
  };

  const translate = async () => {
    try {
      const message = `prevedi mi ${sideDishRef?.current?.value} na jezike ${languages} i vrati samo ovo:
      side_dish_bs: prijevod
      side_dish_en: prijevod
      side_dish_de: prijevod
      side_dish_es: prijevod
      side_dish_fr: prijevod
      side_dish_it: prijevod
      side_dish_tr: prijevod
      side_dish_sa: prijevod
      side_dish_rs: prijevod
      side_dish_sr: prijevod
      side_dish_hr: prijevod
      side_dish_me: prijevod
      side_dish_al: prijevod
      side_dish_sl: prijevod
      side_dish_nl: prijevod
      side_dish_at: prijevod
      side_dish_en_us: prijevod
      `;
      const res = await fetch("https://api.openai.com/v1/chat/completions", {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          Authorization:
            "Bearer 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",
        },
        body: JSON.stringify({
          messages: [{ role: "system", content: message }],
          model: "gpt-3.5-turbo",
        }),
      });

      const json = await res.json();

      const translationList = {};
      json.choices[0].message.content.split("\n\n").forEach((translation) => {
        translation.split("\n").forEach((translation) => {
          const [langCode, value] = translation.split(":");
          translation.trim(" ");
          translationList[langCode.trim()] = value?.trim();
        });
      });
      await setTranslation(translationList);
    } finally {
      toast("Tekst je preveden", { type: "success" });
    }
  };

  const handleSaveDish = () => {
    if (selectedDishIndex !== -1) {
      const priceToEdit = parseFloat(editPrice);
      const enteredPrice = parseFloat(price);
      const updatedDish = {
        ...dishArray?.[selectedDishIndex],
        side_dish_bs: editDishes.side_dish_bs,
        side_dish_en: editDishes.side_dish_en,
        side_dish_de: editDishes.side_dish_de,
        side_dish_es: editDishes.side_dish_es,
        side_dish_fr: editDishes.side_dish_fr,
        side_dish_it: editDishes.side_dish_it,
        side_dish_tr: editDishes.side_dish_tr,
        side_dish_sa: editDishes.side_dish_sa,
        side_dish_rs: editDishes.side_dish_rs,
        side_dish_sr: editDishes.side_dish_sr,
        side_dish_hr: editDishes.side_dish_hr,
        side_dish_me: editDishes.side_dish_me,
        side_dish_al: editDishes.side_dish_al,
        side_dish_sl: editDishes.side_dish_sl,
        side_dish_nl: editDishes.side_dish_nl,
        side_dish_at: editDishes.side_dish_at,
        side_dish_en_us: editDishes.side_dish_en_us,
        price: priceToEdit ? priceToEdit.toFixed(2) : enteredPrice.toFixed(2),
      };
      const updatedDishes = [...dishArray];
      updatedDishes[selectedDishIndex] = updatedDish;

      setDishArray(updatedDishes);
      setSelectedDishIndex(-1);
      setEditDishes("");
      setEditPrice("");
    } else {
      if (translation) {
        const enteredPrice = parseFloat(price);
        const newObject = sideDishes.map(() => ({
          side_dish_bs: translation.side_dish_bs,
          side_dish_en: translation.side_dish_en,
          side_dish_de: translation.side_dish_de,
          side_dish_es: translation.side_dish_es,
          side_dish_fr: translation.side_dish_fr,
          side_dish_it: translation.side_dish_it,
          side_dish_tr: translation.side_dish_tr,
          side_dish_sa: translation.side_dish_sa,
          side_dish_rs: translation.side_dish_rs,
          side_dish_sr: translation.side_dish_sr,
          side_dish_hr: translation.side_dish_hr,
          side_dish_me: translation.side_dish_me,
          side_dish_al: translation.side_dish_al,
          side_dish_sl: translation.side_dish_sl,
          side_dish_nl: translation.side_dish_nl,
          side_dish_at: translation.side_dish_at,
          side_dish_en_us: translation.side_dish_en_us,
          price: enteredPrice.toFixed(2),
        }));
        setIsAdding(false);
        setDishArray((prevArray) => {
          if (Array.isArray(prevArray)) {
            return [...prevArray, ...newObject];
          } else {
            return newObject;
          }
        });
        setSideDishes([]);
        setPrice(0);
      } else {
        const initial = 0;
        const enteredPrice = parseFloat(price);
        const newObject = sideDishes.map((side_dish) => ({
          side_dish_bs: side_dish,
          price: enteredPrice ? enteredPrice.toFixed(2) : initial.toFixed(2),
        }));
        setIsAdding(false);
        setDishArray((prevArray) => {
          if (Array.isArray(prevArray)) {
            return [...prevArray, ...newObject];
          } else {
            return newObject;
          }
        });
        setSideDishes([]);
        setPrice(0);
      }
    }
  };

  useEffect(() => {
    if (selectedDishIndex !== -1) {
      const selectedDish = dishArray?.[selectedDishIndex];
      setEditPrice(selectedDish?.price || "");
    }
  }, [selectedDishIndex, dishArray]);

  return (
    <>
      {isAdding ? (
        <div style={{ marginBottom: "10px" }}>
          {sideDishes.map((side_dish, index) => (
            <>
              <div
                key={index}
                style={{ display: "grid", marginBottom: "10px" }}
              >
                <Form.Group>
                  <Form.Label>Name:</Form.Label>
                  <Form.Control
                    id={`nameInput-${index}`}
                    type="text"
                    as="input"
                    ref={sideDishRef}
                    defaultValue={side_dish}
                    onChange={(e) =>
                      handleFieldChange(index, "side_dish", e.target.value)
                    }
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Price:</Form.Label>
                  <Form.Control
                    id={`priceInput-${index}`}
                    type="text"
                    value={price}
                    onChange={(e) =>
                      handleFieldChange(index, "price", e.target.value)
                    }
                  />
                </Form.Group>
              </div>
              <button className="btn btn-primary mr-1" onClick={translate}>
                Translate
              </button>
              <button className="btn btn-primary mr-1" onClick={handleSaveDish}>
                Save
              </button>
              <button className="btn btn-danger" onClick={cancelDish}>
                Cancel
              </button>
            </>
          ))}
        </div>
      ) : (
        <>
          <div>
            <FormLabel>Side dish</FormLabel>
          </div>
          <Button
            style={{ marginBottom: "10px", width: "50%" }}
            variant="standard"
            type="submit"
            onClick={addNewDish}
          >
            Add side dish
          </Button>
          {dishArray &&
            dishArray.length > 0 &&
            (selectedDishIndex === -1 ? (
              <Form.Group>
                <Form.Label>Side dishes</Form.Label>
                <ul>
                  {dishArray
                    ?.sort((a, b) => a.price - b.price)
                    .map((object, index) => (
                      <li
                        key={index}
                        style={{
                          marginBottom: "10px",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            gap: "30px",
                            alignItems: "center",
                            placeContent: "space-between",
                          }}
                        >
                          <div
                            style={{
                              maxWidth: "200px",
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <p>Name: {object?.[`side_dish_${langKey}`]}</p>
                            <p>
                              Price:
                              {object?.price}
                            </p>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              gap: "5px",
                            }}
                          >
                            <button
                              className="btn btn-primary"
                              onClick={() => {
                                setSelectedDishIndex(index);
                                setEditDishes(object);
                                setEditPrice(object.price);
                              }}
                            >
                              Edit
                            </button>
                            <button
                              type="submit"
                              className="btn btn-danger"
                              onClick={() => handleDeleteDish(index)}
                            >
                              Delete
                            </button>
                          </div>
                        </div>
                      </li>
                    ))}
                </ul>
              </Form.Group>
            ) : (
              <div>
                <Form.Group>
                  <Form.Label>Edit side dish:</Form.Label>
                  <Form.Control
                    id="nameInput"
                    type="text"
                    defaultValue={editDishes?.[`side_dish_${langKey}`]}
                    onChange={(e) =>
                      handleEditChange(`side_dish_${langKey}`, e.target.value)
                    }
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Edit Price:</Form.Label>
                  <Form.Control
                    id="editPriceInput"
                    type="text"
                    value={editPrice}
                    onChange={(e) => handleEditChange("price", e.target.value)}
                  />
                </Form.Group>
                <div
                  style={{ display: "flex", gap: "5px", marginBottom: "10px" }}
                >
                  <button className="btn btn-primary" onClick={handleSaveDish}>
                    Update
                  </button>
                  <button className="btn btn-danger" onClick={cancelDish}>
                    Cancel
                  </button>
                </div>
              </div>
            ))}
        </>
      )}
    </>
  );
}
