import { fetchTags, removeTag } from "../apis";
import { useState, useEffect, useContext } from "react";
import AuthContext from "../contexts/AuthContext";
import { Form, Button, Modal } from "react-bootstrap";
import Select from "react-select";
import { BiEdit } from "react-icons/bi";
import { AiOutlineDelete } from "react-icons/ai";
import { toast } from "react-toastify";
import AddTags from "./AddTags";

export default function Tags({ tagAdded }) {
  const [tags, setTags] = useState([]);
  const [selectedTag, setSelectedTag] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const auth = useContext(AuthContext);

  const hideModal = () => setOpenModal(false);

  const fetchAllTags = async () => {
    const json = await fetchTags(auth.token);
    if (json) {
      setTags(json);
    }
  };
  const tag = tags.find((tag) => tag.id === selectedTag?.value);

  useEffect(() => {
    fetchAllTags();
  }, [tagAdded]);

  const formattedTagOptions =
    tags &&
    tags.map((item) => ({
      value: item.id,
      label: item.name,
    }));

  const onRemove = async () => {
    const response = await removeTag(selectedTag.value, auth.token);
    if (response) {
      toast.success(`Tag removed successfully.`);
      fetchAllTags();
      setSelectedTag(null);
    }
  };

  return (
    <>
      <Form.Label className="font-weight-bold">Edit/Remove Tags</Form.Label>
      <Form.Group>
        <Form.Label>Select Tag</Form.Label>
        <div className="d-flex">
          <div style={{ flex: 1 }}>
            <Select
              options={formattedTagOptions}
              value={selectedTag}
              onChange={(selected) => setSelectedTag(selected)}
            />
          </div>
          <Button onClick={() => setOpenModal(true)} variant="link">
            <BiEdit size={20} />
          </Button>
          <Button variant="link" onClick={onRemove}>
            <AiOutlineDelete size={20} color="red" />
          </Button>
        </div>
      </Form.Group>

      <Modal
        id="itemModal"
        className="itemModal"
        show={openModal}
        onHide={hideModal}
        centered
      >
        <div
          style={{
            backgroundColor: "white",
            padding: "20px",
            borderRadius: "5px",
          }}
        >
          <Modal.Body className="modalBody">
            <AddTags
              tag={tag}
              editing={true}
              setOpenModal={setOpenModal}
              setSelectedTag={setSelectedTag}
              fetchAllTags={fetchAllTags}
            />
          </Modal.Body>
        </div>
      </Modal>
    </>
  );
}
