const translations = {
  en: {
    showOrder: "Show order",
    total: "TOTAL",
    chooseTable: "CHOOSE A TABLE",
    note: "NOTE",
    placeOrder: "ORDER",
    size: "Size",
    quantity: "Quantity",
    order: "ORDER",
    chooseSize: "Choose size",
    flavor: "Flavor",
    orderRecieved: "Your order has been received!",
    backToMenu: "BACK TO MENU",
    choose: "Choose",
    sideDishes: "SIDE DISHES",
    addToCart: "ADD TO CART",
    selected: "Selected",
    followOrder: "FOLLOW ORDER",
    completed: "Completed",
    processing: "In process",
    orderStatus: "ORDER STATUS",
    orderNumber: "ORDER NUMBER",
    enjoyInMeal: "Enjoy your meal, bon appétit!",
    visitSocials: "For new offers, visit our social media.",
    pageRefresh: "Refresh the page to check the status.",
    cartContent: "Cart content",
    weRecommend: "Along with this, we recommend",
    back: "Back",
  },
  bs: {
    showOrder: "Prikaži narudžbu",
    total: "UKUPNO",
    chooseTable: "ODABERI STO",
    note: "NAPOMENA",
    placeOrder: "NARUČI",
    size: "Veličina",
    quantity: "Količina",
    order: "NARUDŽBA",
    chooseSize: "Odaberi veličinu",
    flavor: "Okus",
    orderRecieved: "Vaša narudžba je primljena!",
    backToMenu: "NAZAD NA MENI",
    choose: "Izaberi",
    sideDishes: "PRILOZI",
    addToCart: "DODAJ U KORPU",
    selected: "Odabir",
    followOrder: "PRATI NARUDŽBU",
    completed: "Gotovo",
    processing: "U pripremi",
    orderStatus: "STATUS NARUDŽBE",
    orderNumber: "BROJ NARUDŽBE",
    enjoyInMeal: "Uživaj u jelu, prijatno!",
    visitSocials: "Za nove ponude, posjeti naše društvene mreže",
    pageRefresh: "Osvježi stranicu za provjeru statusa.",
    cartContent: "Sadržaj korpe",
    weRecommend: "Uz ovo preporučujemo",
    back: "Nazad",
  },
  de: {
    showOrder: "Zeige bestellung",
    total: "INSGESAMT",
    chooseTable: "WÄHLE EINEN TISCH",
    note: "HINWEIS",
    placeOrder: "BESTELLUNG",
    size: "Größe",
    quantity: "Menge",
    order: "BESTELLUNG",
    chooseSize: "Größe wählen",
    flavor: "Geschmack",
    orderRecieved: "Ihre Bestellung wurde erhalten!",
    backToMenu: "ZURÜCK ZUM MENÜ",
    choose: "Wählen",
    sideDishes: "BEILAGEN",
    addToCart: "IN DEN WARENKORB LEGEN",
    selected: "Auswahl",
    followOrder: "FOLGE BESTELLUNG",
    completed: "abgeschlossen",
    processing: "in bearbeitung",
    orderStatus: "BESTELLSTATUS",
    orderNumber: "BESTELLNUMMER",
    enjoyInMeal: "Guten Appetit!",
    visitSocials: "Für neue Angebote, besuche unsere sozialen Medien.",
    pageRefresh: "Aktualisiere die Seite, um den Status zu überprüfen.",
    cartContent: "Inhalt des Warenkorbs",
    weRecommend: "Dazu empfehlen wir",
    back: "Zurück",
  },
  es: {
    showOrder: "Mostrar orden",
    total: "TOTAL",
    chooseTable: "ELIGE UNA MESA",
    note: "NOTA",
    placeOrder: "ORDERNAR",
    size: "Tamaño",
    quantity: "Cantidad",
    order: "ORDEN",
    chooseSize: "Elige el tamaño",
    flavor: "Sabor",
    orderRecieved: "¡Se ha recibido su pedido!",
    backToMenu: "VOLVER AL MENÚ",
    choose: "Elegir",
    sideDishes: "ACOMPAÑAMIENTOS",
    addToCart: "AGREGAR AL CARRITO",
    selected: "Elección",
    followOrder: "SEGUIR ORDEN",
    completed: "Completado",
    processing: "en proceso",
    orderStatus: "ESTADO DEL PEDIDO",
    orderNumber: "NÚMERO DE PEDIDO",
    enjoyInMeal: "¡Disfruta tu comida, buen provecho!",
    visitSocials: "Para nuevas ofertas, visita nuestras redes sociales.",
    pageRefresh: "Actualiza la página para verificar el estado.",
    cartContent: "Contenido del carrito",
    weRecommend: "Junto con esto, recomendamos",
    back: "Atrás",
  },
  fr: {
    showOrder: "Afficher la commande",
    total: "TOTAL",
    chooseTable: "CHOISISSEZ UNE TABLE",
    note: "NOTE",
    placeOrder: "COMMANDER",
    size: "Taille",
    quantity: "Quantité",
    order: "COMMANDE",
    chooseSize: "Choisissez la taille",
    flavor: "Saveur",
    orderRecieved: "Votre commande a été reçue !",
    backToMenu: "RETOUR AU MENU",
    choose: "Choisir",
    sideDishes: "GARNITURES",
    addToCart: "AJOUTER AU PANIER",
    selected: "Choix",
    followOrder: "SUIVRE COMMANDE",
    completed: "terminé",
    processing: "en cours de traitement",
    orderStatus: "ÉTAT DE LA COMMANDE",
    orderNumber: "NUMÉRO DE COMMANDE",
    enjoyInMeal: "Bon appétit!",
    visitSocials: "Pour de nouvelles offres, visitez nos réseaux sociaux.",
    pageRefresh: "Rafraîchissez la page pour vérifier le statut.",
    cartContent: "Contenu du panier",
    weRecommend: "Avec cela, nous recommandons",
    back: "Retour",
  },
  it: {
    showOrder: "Mostra ordine",
    total: "TOTALE",
    chooseTable: "SCEGLI UN TAVOLO",
    note: "NOTA",
    placeOrder: "ORDINARE",
    size: "Dimensione",
    quantity: "Quantita",
    order: "ORDINE",
    chooseSize: "Scegli la taglia",
    flavor: "Gusto",
    orderRecieved: "Il vostro ordine è stato ricevuto!",
    backToMenu: "INDIETRO AL MENU",
    choose: "Scegliere",
    sideDishes: "CONTORNI",
    addToCart: "AGGIUNGI AL CARRELLO",
    selected: "Scelta",
    followOrder: "SEGUI ORDINE",
    completed: "completato",
    processing: "in corso",
    orderStatus: "STATO DELL'ORDINE",
    orderNumber: "NUMERO D'ORDINE",
    enjoyInMeal: "Buon appetito!",
    visitSocials: "Per nuove offerte, visita i nostri social media.",
    pageRefresh: "Aggiorna la pagina per verificare lo stato.",
    cartContent: "Contenuto del carrello",
    weRecommend: "Insieme a questo, consigliamo",
    back: "Indietro",
  },
  tr: {
    showOrder: "Siparişi göster",
    total: "TOPLAM",
    chooseTable: "BİR MASA SEÇ",
    note: "NOT",
    placeOrder: "SİPARİŞ VER",
    size: "Boyut",
    quantity: "Miktar",
    order: "SİPARİŞ",
    chooseSize: "Boyut seçin",
    flavor: "Tat",
    orderRecieved: "Siparişiniz alındı!",
    backToMenu: "MENÜYE GERİ DÖN",
    choose: "Seç",
    sideDishes: "YAN LEZZETLER",
    addToCart: "SEPETE EKLE",
    selected: "Seçim",
    followOrder: "SİPARİŞ TAKİP",
    completed: "tamamlandı",
    processing: "işlemde",
    orderStatus: "SİPARİŞ DURUMU",
    orderNumber: "SİPARİŞ NUMARASI",
    enjoyInMeal: "Afiyet olsun!",
    visitSocials: "Yeni teklifler için sosyal medya hesaplarımızı ziyaret et.",
    pageRefresh: "Durumu kontrol etmek için sayfayı yenile.",
    cartContent: "Sepet içeriği",
    weRecommend: "Bununla birlikte, öneriyoruz",
    back: "Geri",
  },
  sa: {
    showOrder: "عرض الطلب",
    total: "المجموع",
    chooseTable: "اختر طاولة",
    note: "ملاحظة",
    placeOrder: "قدم الطلب",
    size: "الحجم",
    quantity: "الكمية",
    order: "طلب",
    chooseSize: "اختر الحجم",
    flavor: "النكهة",
    orderRecieved: "تم استلام طلبك!",
    backToMenu: "العودة إلى القائمة",
    choose: "اختر",
    sideDishes: "الأطباق الجانبية",
    addToCart: "أضف إلى السلة",
    selected: "محدد",
    followOrder: "تتبع الطلب",
    completed: "مكتمل",
    processing: "قيد المعالجة",
    orderStatus: "حالة الطلب",
    orderNumber: "رقم الطلب",
    enjoyInMeal: "بالهنا والشفا!",
    visitSocials:
      "قم بزيارة حساباتنا على وسائل التواصل الاجتماعي للحصول على عروض جديدة.",
    pageRefresh: "قم بتحديث الصفحة للتحقق من الحالة.",
    cartContent: " محتوى السلة",
    weRecommend: "مع هذا، نوصي",
    back: "عودة",
  },
  rs: {
    showOrder: "Prikaži narudžbu",
    total: "UKUPNO",
    chooseTable: "IZABERI STO",
    note: "NAPOMENA",
    placeOrder: "NARUČI",
    size: "Veličina",
    quantity: "Količina",
    order: "NARUČI",
    chooseSize: "Izaberi veličinu",
    flavor: "Ukus",
    orderRecieved: "Vaša narudžba je primljena!",
    backToMenu: "NAZAD NA MENI",
    choose: "Izaberi",
    sideDishes: "PRILOZI",
    addToCart: "DODAJ U KORPU",
    selected: "Izabrano",
    followOrder: "PRATI NARUDŽBU",
    completed: "Završeno",
    processing: "U procesu",
    orderStatus: "STATUS NARUDŽBE",
    orderNumber: "BROJ NARUDŽBE",
    enjoyInMeal: "Uživajte u obroku, prijatno!",
    visitSocials: "Za nove ponude, posetite naše društvene mreže.",
    pageRefresh: "Osvežite stranicu da proverite status.",
    cartContent: "Sadržaj korpe",
    weRecommend: "Uz ovo, preporučujemo",
    back: "Nazad",
  },
  sr: {
    showOrder: "Прикажи наруџбу",
    total: "УКУПНО",
    chooseTable: "ИЗАБЕРИ СТО",
    note: "НАПОМЕНА",
    placeOrder: "НАРУЧИ",
    size: "Величина",
    quantity: "Количина",
    order: "НАРУЧИ",
    chooseSize: "Изабери величину",
    flavor: "Укус",
    orderRecieved: "Ваша наруџба је примљена!",
    backToMenu: "НАЗАД НА МЕНИ",
    choose: "Изабери",
    sideDishes: "ПРИЛОЗИ",
    addToCart: "ДОДАЈ У КОРПУ",
    selected: "Изабрано",
    followOrder: "ПРАТИ НАРУЏБУ",
    completed: "Завршено",
    processing: "У процесу",
    orderStatus: "СТАТУС НАРУЏБЕ",
    orderNumber: "БРОЈ НАРУЏБЕ",
    enjoyInMeal: "Уживајте у оброку, пријатно!",
    visitSocials: "За нове понуде, посетите наше друштвене мреже.",
    pageRefresh: "Освежите страницу да проверите статус.",
    cartContent: "Садржај корпе",
    weRecommend: "Уз ово, препоручујемо",
    back: "Назад",
  },
  hr: {
    showOrder: "Prikaži narudžbu",
    total: "UKUPNO",
    chooseTable: "IZABERI STOL",
    note: "NAPOMENA",
    placeOrder: "NARUČI",
    size: "Veličina",
    quantity: "Količina",
    order: "NARUČI",
    chooseSize: "Izaberi veličinu",
    flavor: "Okus",
    orderRecieved: "Vaša narudžba je primljena!",
    backToMenu: "NAZAD NA MENI",
    choose: "Izaberi",
    sideDishes: "PRILOZI",
    addToCart: "DODAJ U KOŠARICU",
    selected: "Izabrano",
    followOrder: "PRATI NARUDŽBU",
    completed: "Završeno",
    processing: "U procesu",
    orderStatus: "STATUS NARUDŽBE",
    orderNumber: "BROJ NARUDŽBE",
    enjoyInMeal: "Uživajte u obroku, dobar tek!",
    visitSocials: "Za nove ponude, posjetite naše društvene mreže.",
    pageRefresh: "Osvježite stranicu da provjerite status.",
    cartContent: "Sadržaj košarice",
    weRecommend: "Uz ovo, preporučujemo",
    back: "Nazad",
  },
  me: {
    showOrder: "Prikaži narudžbu",
    total: "UKUPNO",
    chooseTable: "IZABERI STO",
    note: "NAPOMENA",
    placeOrder: "NARUČI",
    size: "Veličina",
    quantity: "Količina",
    order: "NARUČI",
    chooseSize: "Izaberi veličinu",
    flavor: "Ukus",
    orderRecieved: "Vaša narudžba je primljena!",
    backToMenu: "NAZAD NA MENI",
    choose: "Izaberi",
    sideDishes: "PRILOZI",
    addToCart: "DODAJ U KORPU",
    selected: "Izabrano",
    followOrder: "PRATI NARUDŽBU",
    completed: "Završeno",
    processing: "U procesu",
    orderStatus: "STATUS NARUDŽBE",
    orderNumber: "BROJ NARUDŽBE",
    enjoyInMeal: "Uživajte u obroku, prijatno!",
    visitSocials: "Za nove ponude, posjetite naše društvene mreže.",
    pageRefresh: "Osvežite stranicu da proverite status.",
    cartContent: "Sadržaj korpe",
    weRecommend: "Uz ovo, preporučujemo",
    back: "Nazad",
  },
  al: {
    showOrder: "Shfaq porosinë",
    total: "TOTALI",
    chooseTable: "ZGJIDH NJË TAVOLINË",
    note: "SHËNIM",
    placeOrder: "POROSIT",
    size: "Madhësia",
    quantity: "Sasia",
    order: "POROSIT",
    chooseSize: "Zgjidh madhësinë",
    flavor: "Shija",
    orderRecieved: "Porosia juaj është pranuar!",
    backToMenu: "KTHEHU NË MENY",
    choose: "Zgjidh",
    sideDishes: "GARNITURA",
    addToCart: "SHTO NË SHPORTË",
    selected: "Zgjedhur",
    followOrder: "NDIQ POROSINË",
    completed: "Përfunduar",
    processing: "Në proces",
    orderStatus: "STATUSI I POROSISË",
    orderNumber: "NUMRI I POROSISË",
    enjoyInMeal: "Shijoni ushqimin tuaj, ju bëftë mirë!",
    visitSocials: "Për oferta të reja, vizitoni rrjetet tona sociale.",
    pageRefresh: "Rifreskoni faqen për të kontrolluar statusin.",
    cartContent: "Përmbajtja e shportës",
    weRecommend: "Së bashku me këtë, ne rekomandojmë",
    back: "Mbrapa",
  },
  sl: {
    showOrder: "Prikaži naročilo",
    total: "SKUPAJ",
    chooseTable: "IZBERI MIZO",
    note: "OPOMBA",
    placeOrder: "NAROČI",
    size: "Velikost",
    quantity: "Količina",
    order: "NAROČI",
    chooseSize: "Izberi velikost",
    flavor: "Okus",
    orderRecieved: "Vaše naročilo je bilo prejeto!",
    backToMenu: "NAZAJ NA MENI",
    choose: "Izberi",
    sideDishes: "PRILOGE",
    addToCart: "DODAJ V KOŠARICO",
    selected: "Izbrano",
    followOrder: "SPREMLJAJ NAROČILO",
    completed: "Dokončano",
    processing: "V obdelavi",
    orderStatus: "STATUS NAROČILA",
    orderNumber: "ŠTEVILKA NAROČILA",
    enjoyInMeal: "Uživajte v obroku, dober tek!",
    visitSocials: "Za nove ponudbe obiščite naše družbene medije.",
    pageRefresh: "Osvežite stran za preverjanje statusa.",
    cartContent: "Vsebina košarice",
    weRecommend: "Skupaj s tem priporočamo",
    back: "Nazaj",
  },
  nl: {
    showOrder: "Toon bestelling",
    total: "TOTAAL",
    chooseTable: "KIES EEN TAFEL",
    note: "OPMERKING",
    placeOrder: "BESTELLEN",
    size: "Grootte",
    quantity: "Aantal",
    order: "BESTELLEN",
    chooseSize: "Kies grootte",
    flavor: "Smaak",
    orderRecieved: "Uw bestelling is ontvangen!",
    backToMenu: "TERUG NAAR MENU",
    choose: "Kies",
    sideDishes: "BIJGERECHTEN",
    addToCart: "IN WINKELWAGEN",
    selected: "Geselecteerd",
    followOrder: "VOLG BESTELLING",
    completed: "Voltooid",
    processing: "In behandeling",
    orderStatus: "BESTELSTATUS",
    orderNumber: "BESTELNUMMER",
    enjoyInMeal: "Geniet van uw maaltijd, eet smakelijk!",
    visitSocials: "Voor nieuwe aanbiedingen, bezoek onze sociale media.",
    pageRefresh: "Ververs de pagina om de status te controleren.",
    cartContent: "Inhoud winkelwagen",
    weRecommend: "Samen met dit, raden wij aan",
    back: "Terug",
  },
  at: {
    showOrder: "Bestellung anzeigen",
    total: "GESAMT",
    chooseTable: "WÄHLE EINEN TISCH",
    note: "HINWEIS",
    placeOrder: "BESTELLEN",
    size: "Größe",
    quantity: "Menge",
    order: "BESTELLEN",
    chooseSize: "Wähle Größe",
    flavor: "Geschmack",
    orderRecieved: "Ihre Bestellung wurde erhalten!",
    backToMenu: "ZURÜCK ZUM MENÜ",
    choose: "Wählen",
    sideDishes: "BEILAGEN",
    addToCart: "IN DEN WARENKORB",
    selected: "Ausgewählt",
    followOrder: "BESTELLUNG VERFOLGEN",
    completed: "Abgeschlossen",
    processing: "In Bearbeitung",
    orderStatus: "BESTELLSTATUS",
    orderNumber: "BESTELLNUMMER",
    enjoyInMeal: "Genießen Sie Ihre Mahlzeit, guten Appetit!",
    visitSocials: "Für neue Angebote besuchen Sie unsere sozialen Medien.",
    pageRefresh: "Aktualisieren Sie die Seite, um den Status zu überprüfen.",
    cartContent: "Warenkorbinhalt",
    weRecommend: "Zusammen mit diesem empfehlen wir",
    back: "Zurück",
  },
  en_us: {
    showOrder: "Show order",
    total: "TOTAL",
    chooseTable: "CHOOSE A TABLE",
    note: "NOTE",
    placeOrder: "ORDER",
    size: "Size",
    quantity: "Quantity",
    order: "ORDER",
    chooseSize: "Choose size",
    flavor: "Flavor",
    orderRecieved: "Your order has been received!",
    backToMenu: "BACK TO MENU",
    choose: "Choose",
    sideDishes: "SIDE DISHES",
    addToCart: "ADD TO CART",
    selected: "Selected",
    followOrder: "FOLLOW ORDER",
    completed: "Completed",
    processing: "In process",
    orderStatus: "ORDER STATUS",
    orderNumber: "ORDER NUMBER",
    enjoyInMeal: "Enjoy your meal, bon appétit!",
    visitSocials: "For new offers, visit our social media.",
    pageRefresh: "Refresh the page to check the status.",
    cartContent: "Cart content",
    weRecommend: "Along with this, we recommend",
    back: "Back",
  },
};
export default translations;
