import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import React from "react";

import { AuthProvider } from "../contexts/AuthContext";
import PrivateRoute from "./PrivateRoute";
import Login from "../pages/Login";
import Places from "../pages/Places";
import Place from "../pages/Place";
import HomeMenu from "../pages/HomeMenu";
import Orders from "../pages/Orders";
import MenuSettings from "../pages/MenuSettings";
import Menu from "../pages/Menu";
import OrderStatus from "../pages/OrderStatus";
import CategoryPage from "../pages/CategoryPage";
import { LanguageProvider } from "../contexts/LanguageContext";
import { PlaceProvider } from "../contexts/PlaceContext";
import ShoppingCart from "../components/ShoppingCart";

function App() {
  // index.js or App.js
  function setViewportHeight() {
    // Get the viewport height and multiply it by 1% to get a value for a vh unit
    let vh = window.innerHeight * 0.01;
    // Set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  }

  // Initial calculation
  setViewportHeight();

  // Recalculate on resize
  window.addEventListener("resize", setViewportHeight);

  return (
    <div className="content">
      <LanguageProvider>
        <AuthProvider>
          <PlaceProvider>
            <BrowserRouter>
              <Routes>
                <Route exact path="/" element={<Login />}></Route>
                <Route exact path="/login" element={<Login />}></Route>
                <Route exact path="/menu/:id" element={<HomeMenu />}></Route>
                <Route
                  exact
                  path="/menu/:id/:categoryId/items"
                  element={<Menu />}
                ></Route>
                <Route
                  exact
                  path="/menu/:placeId/:categoryId/items/orderStatus/:orderId"
                  element={<OrderStatus />}
                ></Route>
                <Route
                  exact
                  path="/menu/:id/categories"
                  element={<CategoryPage />}
                ></Route>
                <Route
                  exact
                  path="/menu/:id/order"
                  element={<ShoppingCart />}
                ></Route>
                <Route
                  path="/places/:id"
                  element={
                    <PrivateRoute>
                      <Place />
                    </PrivateRoute>
                  }
                ></Route>
                <Route
                  path="/places/*"
                  element={
                    <PrivateRoute>
                      <Places />
                    </PrivateRoute>
                  }
                ></Route>
                <Route
                  path="/places/:id/orders"
                  element={
                    <PrivateRoute>
                      <Orders />
                    </PrivateRoute>
                  }
                ></Route>
                <Route
                  path="/places/:id/settings"
                  element={
                    <PrivateRoute>
                      <MenuSettings />
                    </PrivateRoute>
                  }
                ></Route>

                {/* <PrivateRoute exact path='/places/:id' element={<Place />}></PrivateRoute>
            <PrivateRoute exact path='/places' element={<Places />}></PrivateRoute>
            <PrivateRoute exact path='/places/:id/orders' element={<Orders />}></PrivateRoute>
          <PrivateRoute exact path='/places/:id/settings' element={<MenuSettings />}></PrivateRoute> */}
              </Routes>
            </BrowserRouter>
            <ToastContainer />
          </PlaceProvider>
        </AuthProvider>
      </LanguageProvider>
    </div>
  );
}

export default App;
