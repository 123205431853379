import { Button, Form, Tab, Tabs } from "react-bootstrap";
import {
  DE,
  GB,
  ES,
  FR,
  IT,
  TR,
  BA,
  RS,
  HR,
  ME,
  AL,
  SI,
  NL,
  AT,
  US,
} from "country-flag-icons/react/3x2";
import { useEffect, useState, useRef } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { toast } from "react-toastify";
import AddSideDish from "./AddSideDish";
import AddVariants from "./AddVariants";
import arapskaZastava from "../photos/arapska-liga-zastava.png";

const LanguageTabContent = ({
  item,
  langKey,
  language,
  clearInputs,
  nameInputRef,
  descriptionInputRef,
  translation,
  setTranslation,
  dishArray,
  setDishArray,
  selectedDishIndex,
  setSelectedDishIndex,
  variantArray,
  setVariantArray,
  price,
  setPrice,
}) => {
  const [name, setName] = useState(item[`name_${langKey}`] ?? "");

  const [description, setDescription] = useState(
    item[`description_${langKey}`] ?? ""
  );

  const [translating, setTranslating] = useState(false);

  const languages =
    "Bosnian, English, German, Spain, French, Italian, Turkish, Arabic, Serbian Latinic, Serbian Cyrillic, Croatian, Montenegro, Albanian, Slovenian, Dutch, Austrian, Americak";

  const [selectedVariantIndex, setSelectedVariantIndex] = useState(-1);

  useEffect(() => {
    if (translating) {
      Object.keys(translation).forEach((langKey) => {
        item[langKey] = translation[langKey];
      });
    } else {
      item[`name_${langKey}`] = name;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name, translation, langKey]);

  useEffect(() => {
    if (translating) {
      Object.keys(translation).forEach((langKey) => {
        item[langKey] = translation[langKey];
      });
    } else {
      item[`description_${langKey}`] = description;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [description, translation, langKey]);

  useEffect(() => {
    if (translating) {
      if (dishArray) {
        dishArray.map((dish) => {
          Object.keys(translation).forEach((langKey) => {
            dish[langKey] = translation[langKey];
          });
        });
      }
    } else {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      dishArray = [];
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dishArray, translation, langKey]);

  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      [{ font: [] }],
      ["bold", "italic", "underline", "strike", "blackquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image", "video"],
    ],
  };

  const generateDescriptionTranslation = () => {
    if (
      descriptionInputRef?.current?.value.length > 0 &&
      descriptionInputRef?.current?.value !== "<p><br></p>"
    )
      return `then also separately, give me translation of this ${descriptionInputRef?.current?.value} on following languages ${languages} as follows:
      "
      description_bs: translation
      description_en: translation
      description_de: translation
      description_es: translation
      description_fr: translation
      description_it: translation
      description_tr: translation
      description_sa: translation
      description_rs: translation
      description_sr: translation
      description_hr: translation
      description_me: translation
      description_al: translation
      description_sl: translation
      description_nl: translation
      description_at: translation
      description_en_us: translation
      "`;
  };

  const translate = async () => {
    setTranslating(true);
    try {
      const message = `give me translation of ${
        nameInputRef?.current?.value
      } on following languages ${languages} as follows:
      "
      name_bs: translation
      name_en: translation
      name_de: translation
      name_es: translation
      name_fr: translation
      name_it: translation
      name_tr: translation
      name_sa: translation
      name_rs: translation
      name_sr: translation
      name_hr: translation
      name_me: translation
      name_al: translation
      name_sl: translation
      name_nl: translation
      name_at: translation
      name_en_us: translation
      "
      ${
        descriptionInputRef?.current?.value.length > 1 ||
        descriptionInputRef?.current?.value !== "<p><br></p>"
          ? generateDescriptionTranslation()
          : null
      }
      `;
      const res = await fetch("https://api.openai.com/v1/chat/completions", {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          Authorization:
            "Bearer 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",
        },
        body: JSON.stringify({
          messages: [{ role: "system", content: message }],
          model: "gpt-3.5-turbo",
        }),
      });

      const json = await res.json();

      const translationList = {};
      json.choices[0].message.content.split("\n\n").forEach((translation) => {
        translation.split("\n").forEach((translation) => {
          const [langCode, value] = translation.split(":");
          translation.trim(" ");
          translationList[langCode.trim()] = value?.trim();
        });
      });
      await setTranslation(translationList);
    } finally {
      toast("Tekst je preveden", { type: "success" });
      setTranslating(false);
    }
  };

  useEffect(() => {
    setName(translation[`name_${langKey}`] ?? item[`name_${langKey}`]);
    setDescription(
      translation[`description_${langKey}`] ?? item[`description_${langKey}`]
    );
  }, [item, translation, langKey]);

  useEffect(() => {
    if (clearInputs) {
      setName("");
      setDescription("");
      setTranslation("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clearInputs]);

  return (
    <>
      <Form.Group controlId={`${language}-form`}>
        <Form.Label>Name</Form.Label>
        <Form.Control
          type="text"
          as="input"
          placeholder={`Enter name in ${language}`}
          name={`name_${langKey}`}
          key={`name_${langKey}`}
          defaultValue={name}
          ref={nameInputRef}
          onChange={(e) => setName(e.target.value)}
        />
        <Form.Label>Description</Form.Label>
        <ReactQuill
          theme="snow"
          placeholder={`Enter description in ${language}`}
          name={`description_${langKey}`}
          ref={descriptionInputRef}
          key={`description_${langKey}`}
          value={description}
          onChange={setDescription}
          modules={modules}
        />
      </Form.Group>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <Button
          style={{ marginBottom: "10px" }}
          variant="primary"
          type="submit"
          onClick={translate}
        >
          Translate
        </Button>
        <AddSideDish
          dishArray={dishArray}
          setDishArray={setDishArray}
          selectedDishIndex={selectedDishIndex}
          setSelectedDishIndex={setSelectedDishIndex}
          langKey={langKey}
          languages={languages}
        />
        <AddVariants
          variantArray={variantArray}
          setVariantArray={setVariantArray}
          selectedVariantIndex={selectedVariantIndex}
          setSelectedVariantIndex={setSelectedVariantIndex}
          price={price}
          setPrice={setPrice}
          langKey={langKey}
          languages={languages}
        />
      </div>
    </>
  );
};
export default function LanguageTabs({
  place,
  item,
  clearInputs,
  dishArray,
  setDishArray,
  selectedDishIndex,
  setSelectedDishIndex,
  variantArray,
  setVariantArray,
  price,
  setPrice,
}) {
  const font = place.font;

  const [translation, setTranslation] = useState("");

  let nameInputRef = useRef();
  let descriptionInputRef = useRef();
  return (
    <>
      <Tabs
        id="controlled-tab-example"
        className="mb-3"
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(auto-fill, minmax(30px, 1fr))",
          gap: "5px",
          gridAutoFlow: "row",
        }}
      >
        <Tab
          eventKey="lang-bs"
          title={
            <BA
              title="Bosnia and Herzegovina"
              style={{ width: "20px", paddingBottom: "5px" }}
            ></BA>
          }
        >
          <LanguageTabContent
            translation={translation}
            setTranslation={setTranslation}
            item={item}
            clearInputs={clearInputs}
            nameInputRef={nameInputRef}
            descriptionInputRef={descriptionInputRef}
            dishArray={dishArray}
            setDishArray={setDishArray}
            selectedDishIndex={selectedDishIndex}
            setSelectedDishIndex={setSelectedDishIndex}
            variantArray={variantArray}
            setVariantArray={setVariantArray}
            price={price}
            setPrice={setPrice}
            langKey="bs"
            language="Bosnian"
          />
        </Tab>
        <Tab
          eventKey="lang-en"
          className="langTab"
          title={
            <GB
              title="Great Britain"
              style={{ width: "20px", paddingBottom: "5px" }}
            ></GB>
          }
        >
          <LanguageTabContent
            translation={translation}
            setTranslation={setTranslation}
            font={font}
            item={item}
            clearInputs={clearInputs}
            dishArray={dishArray}
            setDishArray={setDishArray}
            selectedDishIndex={selectedDishIndex}
            setSelectedDishIndex={setSelectedDishIndex}
            variantArray={variantArray}
            setVariantArray={setVariantArray}
            price={price}
            setPrice={setPrice}
            langKey="en"
            language="English"
          />
        </Tab>
        <Tab
          eventKey="lang-de"
          title={
            <DE
              title="Germany"
              style={{ width: "20px", paddingBottom: "5px" }}
            ></DE>
          }
        >
          <LanguageTabContent
            translation={translation}
            setTranslation={setTranslation}
            item={item}
            clearInputs={clearInputs}
            dishArray={dishArray}
            setDishArray={setDishArray}
            selectedDishIndex={selectedDishIndex}
            setSelectedDishIndex={setSelectedDishIndex}
            variantArray={variantArray}
            setVariantArray={setVariantArray}
            price={price}
            setPrice={setPrice}
            langKey="de"
            language="German"
          />
        </Tab>
        <Tab
          eventKey="lang-es"
          title={
            <ES
              title="Spain"
              style={{ width: "20px", paddingBottom: "5px" }}
            ></ES>
          }
        >
          <LanguageTabContent
            translation={translation}
            setTranslation={setTranslation}
            item={item}
            clearInputs={clearInputs}
            dishArray={dishArray}
            setDishArray={setDishArray}
            selectedDishIndex={selectedDishIndex}
            setSelectedDishIndex={setSelectedDishIndex}
            variantArray={variantArray}
            setVariantArray={setVariantArray}
            price={price}
            setPrice={setPrice}
            langKey="es"
            language="Spanish"
          />
        </Tab>
        <Tab
          eventKey="lang-fr"
          title={
            <FR
              title="France"
              style={{ width: "20px", paddingBottom: "5px" }}
            ></FR>
          }
        >
          <LanguageTabContent
            translation={translation}
            setTranslation={setTranslation}
            item={item}
            clearInputs={clearInputs}
            dishArray={dishArray}
            setDishArray={setDishArray}
            selectedDishIndex={selectedDishIndex}
            setSelectedDishIndex={setSelectedDishIndex}
            variantArray={variantArray}
            setVariantArray={setVariantArray}
            price={price}
            setPrice={setPrice}
            langKey="fr"
            language="French"
          />
        </Tab>
        <Tab
          eventKey="lang-it"
          title={
            <IT
              title="Italy"
              style={{ width: "20px", paddingBottom: "5px" }}
            ></IT>
          }
        >
          <LanguageTabContent
            translation={translation}
            setTranslation={setTranslation}
            item={item}
            clearInputs={clearInputs}
            dishArray={dishArray}
            setDishArray={setDishArray}
            selectedDishIndex={selectedDishIndex}
            setSelectedDishIndex={setSelectedDishIndex}
            variantArray={variantArray}
            setVariantArray={setVariantArray}
            price={price}
            setPrice={setPrice}
            langKey="it"
            language="Italian"
          />
        </Tab>
        <Tab
          eventKey="lang-tr"
          title={
            <TR
              title="Turkie"
              style={{ width: "20px", paddingBottom: "5px" }}
            ></TR>
          }
        >
          <LanguageTabContent
            translation={translation}
            setTranslation={setTranslation}
            item={item}
            clearInputs={clearInputs}
            dishArray={dishArray}
            setDishArray={setDishArray}
            selectedDishIndex={selectedDishIndex}
            setSelectedDishIndex={setSelectedDishIndex}
            variantArray={variantArray}
            setVariantArray={setVariantArray}
            price={price}
            setPrice={setPrice}
            langKey="tr"
            language="Turkish"
          />
        </Tab>
        <Tab
          eventKey="lang-sa"
          title={
            <img
              alt="arab-flag"
              src={arapskaZastava}
              style={{ width: "20px", paddingBottom: "5px" }}
            ></img>
          }
        >
          <LanguageTabContent
            translation={translation}
            setTranslation={setTranslation}
            item={item}
            clearInputs={clearInputs}
            dishArray={dishArray}
            setDishArray={setDishArray}
            selectedDishIndex={selectedDishIndex}
            setSelectedDishIndex={setSelectedDishIndex}
            variantArray={variantArray}
            setVariantArray={setVariantArray}
            price={price}
            setPrice={setPrice}
            langKey="sa"
            language="العربية"
          />
        </Tab>
        <Tab
          eventKey="lang-rs"
          title={
            <RS
              title="Serbia Latinic"
              style={{ width: "20px", paddingBottom: "5px" }}
            ></RS>
          }
        >
          <LanguageTabContent
            translation={translation}
            setTranslation={setTranslation}
            item={item}
            clearInputs={clearInputs}
            dishArray={dishArray}
            setDishArray={setDishArray}
            selectedDishIndex={selectedDishIndex}
            setSelectedDishIndex={setSelectedDishIndex}
            variantArray={variantArray}
            setVariantArray={setVariantArray}
            price={price}
            setPrice={setPrice}
            langKey="rs"
            language="Serbian Latinic"
          />
        </Tab>
        <Tab
          eventKey="lang-sr"
          title={
            <RS
              title="Serbia Cyrillic"
              style={{ width: "20px", paddingBottom: "5px" }}
            ></RS>
          }
        >
          <LanguageTabContent
            translation={translation}
            setTranslation={setTranslation}
            item={item}
            clearInputs={clearInputs}
            dishArray={dishArray}
            setDishArray={setDishArray}
            selectedDishIndex={selectedDishIndex}
            setSelectedDishIndex={setSelectedDishIndex}
            variantArray={variantArray}
            setVariantArray={setVariantArray}
            price={price}
            setPrice={setPrice}
            langKey="sr"
            language="Serbian Cyrillic"
          />
        </Tab>
        <Tab
          eventKey="lang-hr"
          title={
            <HR
              title="Croatia"
              style={{ width: "20px", paddingBottom: "5px" }}
            ></HR>
          }
        >
          <LanguageTabContent
            translation={translation}
            setTranslation={setTranslation}
            item={item}
            clearInputs={clearInputs}
            dishArray={dishArray}
            setDishArray={setDishArray}
            selectedDishIndex={selectedDishIndex}
            setSelectedDishIndex={setSelectedDishIndex}
            variantArray={variantArray}
            setVariantArray={setVariantArray}
            price={price}
            setPrice={setPrice}
            langKey="hr"
            language="Croatian"
          />
        </Tab>
        <Tab
          eventKey="lang-me"
          title={
            <ME
              title="Montenegro"
              style={{ width: "20px", paddingBottom: "5px" }}
            ></ME>
          }
        >
          <LanguageTabContent
            translation={translation}
            setTranslation={setTranslation}
            item={item}
            clearInputs={clearInputs}
            dishArray={dishArray}
            setDishArray={setDishArray}
            selectedDishIndex={selectedDishIndex}
            setSelectedDishIndex={setSelectedDishIndex}
            variantArray={variantArray}
            setVariantArray={setVariantArray}
            price={price}
            setPrice={setPrice}
            langKey="me"
            language="Montenegro"
          />
        </Tab>
        <Tab
          eventKey="lang-al"
          title={
            <AL
              title="Albanian"
              style={{ width: "20px", paddingBottom: "5px" }}
            ></AL>
          }
        >
          <LanguageTabContent
            translation={translation}
            setTranslation={setTranslation}
            item={item}
            clearInputs={clearInputs}
            dishArray={dishArray}
            setDishArray={setDishArray}
            selectedDishIndex={selectedDishIndex}
            setSelectedDishIndex={setSelectedDishIndex}
            variantArray={variantArray}
            setVariantArray={setVariantArray}
            price={price}
            setPrice={setPrice}
            langKey="al"
            language="Albanian"
          />
        </Tab>
        <Tab
          eventKey="lang-sl"
          title={
            <SI
              title="Slovenian"
              style={{ width: "20px", paddingBottom: "5px" }}
            ></SI>
          }
        >
          <LanguageTabContent
            translation={translation}
            setTranslation={setTranslation}
            item={item}
            clearInputs={clearInputs}
            dishArray={dishArray}
            setDishArray={setDishArray}
            selectedDishIndex={selectedDishIndex}
            setSelectedDishIndex={setSelectedDishIndex}
            variantArray={variantArray}
            setVariantArray={setVariantArray}
            price={price}
            setPrice={setPrice}
            langKey="sl"
            language="Slovenia"
          />
        </Tab>
        <Tab
          eventKey="lang-nl"
          title={
            <NL
              title="Dutch"
              style={{ width: "20px", paddingBottom: "5px" }}
            ></NL>
          }
        >
          <LanguageTabContent
            translation={translation}
            setTranslation={setTranslation}
            item={item}
            clearInputs={clearInputs}
            dishArray={dishArray}
            setDishArray={setDishArray}
            selectedDishIndex={selectedDishIndex}
            setSelectedDishIndex={setSelectedDishIndex}
            variantArray={variantArray}
            setVariantArray={setVariantArray}
            price={price}
            setPrice={setPrice}
            langKey="nl"
            language="Dutch"
          />
        </Tab>
        <Tab
          eventKey="lang-at"
          title={
            <AT
              title="Austrian"
              style={{ width: "20px", paddingBottom: "5px" }}
            ></AT>
          }
        >
          <LanguageTabContent
            translation={translation}
            setTranslation={setTranslation}
            item={item}
            clearInputs={clearInputs}
            dishArray={dishArray}
            setDishArray={setDishArray}
            selectedDishIndex={selectedDishIndex}
            setSelectedDishIndex={setSelectedDishIndex}
            variantArray={variantArray}
            setVariantArray={setVariantArray}
            price={price}
            setPrice={setPrice}
            langKey="at"
            language="Austria"
          />
        </Tab>
        <Tab
          eventKey="lang-en_us"
          title={
            <US
              title="English"
              style={{ width: "20px", paddingBottom: "5px" }}
            ></US>
          }
        >
          <LanguageTabContent
            translation={translation}
            setTranslation={setTranslation}
            item={item}
            clearInputs={clearInputs}
            dishArray={dishArray}
            setDishArray={setDishArray}
            selectedDishIndex={selectedDishIndex}
            setSelectedDishIndex={setSelectedDishIndex}
            variantArray={variantArray}
            setVariantArray={setVariantArray}
            price={price}
            setPrice={setPrice}
            langKey="en_us"
            language="English"
          />
        </Tab>
      </Tabs>
      <hr></hr>
    </>
  );
}
