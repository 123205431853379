import React, { useState } from "react";
import { Modal, Container, Row, Col, Button } from "react-bootstrap";
import QRCode from "./QRCode";
import OperationButton from "./OperationButton";
import { FaRegEdit, FaCheck } from "react-icons/fa";

const QRCodeModal = ({
  show,
  onHide,
  place,
  onUpdatePlace,
  tableCategories,
  setTableCategories,
  numberOfTables,
  setNumberOfTables,
  onDone,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editedCategoryName, setEditedCategoryName] = useState("");
  const [editIndex, setEditIndex] = useState(null);

  const addNewTableCategory = () => {
    const updatedTableCategory = {
      table_category: "Category name",
      tables: 0,
    };
    if (!tableCategories) {
      setTableCategories([updatedTableCategory]);
    } else {
      setTableCategories([...tableCategories, updatedTableCategory]);
    }
  };

  const removeTableCategory = () => {
    if (tableCategories.length > 0) {
      const updatedTableCategories = tableCategories.slice(0, -1);
      setTableCategories(updatedTableCategories);
    }
  };

  const handleEditCategoryName = (index) => {
    const table = tableCategories[index];
    setIsEditing(true);
    setEditIndex(index);
    setEditedCategoryName(table.table_category);
  };

  const saveEditedCategoryName = () => {
    setIsEditing(false);
    const updatedTableCategories = [...tableCategories];
    updatedTableCategories[editIndex].table_category = editedCategoryName;
    setTableCategories(updatedTableCategories);
  };

  const addNewTable = (index) => {
    const updatingTable = tableCategories[index];
    if (updatingTable && updatingTable.tables !== undefined) {
      updatingTable.tables += 1;
    }
    const updatedTables = [...tableCategories];
    if (index >= 0 && index < updatedTables.length) {
      updatedTables[index] = updatingTable;
      setTableCategories(updatedTables);
    }
  };

  const removeTable = (index) => {
    const updatingTable = tableCategories[index];
    if (updatingTable && updatingTable.tables !== undefined) {
      updatingTable.tables -= 1;
    }
    const updatedTables = [...tableCategories];
    if (index >= 0 && index < updatedTables.length) {
      updatedTables[index] = updatingTable;
      setTableCategories(updatedTables);
    }
  };

  // useEffect(() => {
  //   onUpdatePlace();
  // }, [numberOfTables]);

  // useEffect(() => {
  //   onUpdatePlace(tableCategories);
  // }, [tableCategories]);

  return (
    <Modal show={show} onHide={onHide} size="lg" centered>
      <div style={{ backgroundColor: "white" }}>
        <Modal.Body className="text-center pt-4">
          <Container>
            <div
              style={{ gap: "10px" }}
              className="d-flex align-items-center justify-content-center"
            >
              <h3>Tables QR Code</h3>
              <Button
                variant="standard"
                onClick={() => {
                  onUpdatePlace();
                  onHide();
                }}
              >
                SAVE CHANGES
              </Button>
            </div>
            <div className="d-flex align-items-center mt-4 mb-4">
              <h5 className="mb-0 mr-2">
                Total categories: <b>{tableCategories?.length}</b>
              </h5>

              <OperationButton
                variant="lightgray"
                size="sm"
                disabled={tableCategories?.length === 0}
                onClick={removeTableCategory}
              >
                -
              </OperationButton>
              <OperationButton
                variant="lightgray"
                size="sm"
                onClick={addNewTableCategory}
              >
                +
              </OperationButton>
            </div>
            <Row>
              {tableCategories?.map((tableCategory, index) => (
                <React.Fragment key={index}>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <div className="d-flex" style={{ gap: "10px" }}>
                      <p style={{ margin: "0" }}>
                        <b>Category:</b>{" "}
                      </p>
                      {isEditing && editIndex === index ? (
                        <div>
                          <input
                            style={{ marginRight: "10px" }}
                            type="text"
                            value={editedCategoryName}
                            onChange={(e) =>
                              setEditedCategoryName(e.target.value)
                            }
                          />
                          <FaCheck
                            style={{ cursor: "pointer" }}
                            color="green"
                            size={20}
                            onClick={saveEditedCategoryName}
                          />
                        </div>
                      ) : (
                        <div className="d-flex" style={{ gap: "5px" }}>
                          <p>{tableCategory.table_category}</p>
                          <FaRegEdit
                            style={{ cursor: "pointer" }}
                            size={20}
                            color="#027BFF"
                            onClick={() => handleEditCategoryName(index)}
                          />
                        </div>
                      )}
                    </div>
                    <div className="d-flex">
                      <p>
                        <b>Tables:</b> {tableCategory.tables}
                      </p>
                      <OperationButton
                        variant="lightgray"
                        size="sm"
                        disabled={tableCategory.tables === 0}
                        onClick={() => {
                          removeTable(index);
                          onUpdatePlace();
                        }}
                      >
                        -
                      </OperationButton>
                      <OperationButton
                        variant="lightgray"
                        size="sm"
                        onClick={() => {
                          addNewTable(index);
                          onUpdatePlace();
                        }}
                      >
                        +
                      </OperationButton>
                    </div>
                  </div>
                  <div
                    className="d-flex"
                    style={{ width: "100%", flexWrap: "wrap" }}
                  >
                    {Array.from(
                      { length: tableCategory.tables },
                      (_, i) => i + 1
                    ).map((table) => (
                      <Col key={table} lg={4} md={6} className="mb-4">
                        <QRCode table={table} placeId={place.id} />
                      </Col>
                    ))}
                  </div>
                </React.Fragment>
              ))}
            </Row>
            <Row>
              <p>
                <b>Tables: {numberOfTables}</b>
              </p>
              <OperationButton
                variant="lightgray"
                size="sm"
                disabled={numberOfTables === 0}
                onClick={() => {
                  setNumberOfTables((prev) => prev - 1);
                  onUpdatePlace();
                }}
              >
                -
              </OperationButton>
              <OperationButton
                variant="lightgray"
                size="sm"
                onClick={() => {
                  setNumberOfTables((prev) => prev + 1);
                  onUpdatePlace();
                }}
              >
                +
              </OperationButton>
              <div
                className="d-flex"
                style={{ width: "100%", flexWrap: "wrap" }}
              >
                {Array.from({ length: numberOfTables }, (_, i) => i + 1).map(
                  (table) => (
                    <Col key={table} lg={4} md={6} className="mb-4">
                      <QRCode table={table} placeId={place.id} />
                    </Col>
                  )
                )}
              </div>
            </Row>
          </Container>
        </Modal.Body>
      </div>
    </Modal>
  );
};

export default QRCodeModal;
