import translations from "./Translations";

const Translation = ({ language }) => {
  const translation = translations[language] || translations.en;
  const translate = (key) => translation[key] || key;
  return {
    translate,
  };
};
export default Translation;
