import { useNavigate } from "react-router";
import correct from "../photos/correct.png";
import Translation from "./Translation";
import { useContext } from "react";
import LanguageContext from "../contexts/LanguageContext";

import { TiArrowBack } from "react-icons/ti";
import { LiaClipboardListSolid } from "react-icons/lia";
import { Spinner } from "react-bootstrap";

export default function OrderAcceptModal({ order }) {
  const navigate = useNavigate();
  const { language } = useContext(LanguageContext);
  const { translate } = Translation({ language });

  return (
    <>
      <div
        style={{
          display: "grid",
          justifyContent: "center",
          backgroundColor: "white",
          paddingTop: "30px",
          paddingBottom: "30px",
          borderRadius: "40px",
          marginBottom: "40px",
        }}
      >
        {order ? (
          <img
            alt="correct"
            src={correct}
            width={"150px"}
            style={{
              display: "flex",
              justifySelf: "center",
            }}
          />
        ) : (
          <div
            style={{
              width: "126px",
              height: "126px",
              display: "flex",
              justifySelf: "center",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Spinner
              style={{
                width: "60px",
                height: "60px",
                display: "flex",
                justifySelf: "center",
              }}
              variant="success"
              animation="border"
              role="staus"
            />
          </div>
        )}
        <>
          <p style={{ fontSize: "2.5vh", textAlign: "center", margin: "0px" }}>
            {translate("orderRecieved")}
          </p>
          <p style={{ fontSize: "3vh" }}>
            <b>BROJ NARUDŽBE: #{order?.id}</b>
          </p>
        </>
      </div>
      <div style={{ display: "flex", justifyContent: "space-around" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            color: "white",
            gap: "10px",
          }}
        >
          <TiArrowBack
            onClick={() => navigate(-1)}
            style={{
              color: "white",
              border: "4px solid #31c1b8",
              borderRadius: "100%",
              padding: "10px",
              backgroundColor: "transparent",
              cursor: "pointer",
            }}
            size={80}
          />
          {translate("backToMenu")}
        </div>
        <button
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            opacity: order ? 1 : 0.5,
            backgroundColor: "transparent",
            border: "none",
            color: "white",
            gap: "10px",
          }}
          disabled={!order}
        >
          <LiaClipboardListSolid
            onClick={() => navigate(`orderStatus/${order.uuid}`)}
            style={{
              opacity: order ? 1 : 0.5,
              border: "4px solid #1ae26c",
              borderRadius: "100%",
              padding: "10px",
              backgroundColor: "transparent",
              cursor: "pointer",
            }}
            size={80}
          />
          {translate("followOrder")}
        </button>
      </div>
    </>
  );
}
