import { Navigate, useLocation } from 'react-router-dom';
import React, { useContext } from 'react';

import AuthContext from '../contexts/AuthContext';

function PrivateRoute({children, ...rest }) {

  const auth = useContext(AuthContext);
  const location = useLocation();
  
  return (
    <>
    {auth.token 
    ? children
    : <Navigate 
        to='/login'
        state= {{ from: location }}
        />}
        </>
  )
}

export default PrivateRoute;